.ebook-text-panel {
	padding-top: var(--section-spacing-l);
    padding-bottom: var(--section-spacing);
	@include mq(medium) {
		padding-top: var(--section-spacing-l);
    	padding-bottom: var(--section-spacing-l);
    }
}

.ebook-text__header {
	text-transform: uppercase;
	color: var(--color-text-light);
	font-size: var(--font-size-small);
}