.page-header--home h1 {
	@include mq(large) {
		font-size: var(--font-size-h1-home);
	}
}
.page-header--home  a:not(.btn) {
    color: var(--color-text-link);
    text-decoration: none;
}

.page-header--home a:not(.btn):hover {
    color: var(--color-text-hover-hover);
    text-decoration: underline;
}