.updates-block {
	background: rgba(245, 243, 238, 0.4);
	padding: var(--spacing);
	margin: var(--section-spacing) 0;
}

.updates-block__header {
	@include mq(large) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.updates-block__header p {
	font-size: var(--font-size-lead);
	margin: 0;
}

.updates-block__seach-btn {
	color: var(--color-text-link);
	text-transform: uppercase;
	padding: 0;
	margin: 0;
	border: 0;
	background: transparent;
	appearance: none;
	cursor: pointer;

	span {
		text-decoration: underline;
	}

	svg {
		height: 14px;
		width: 14px;
		margin-left: 5px;
	}

	&.is-active svg {
		transform: rotate(180deg);
	}
}

.updates-block__body {
	display: none;
}

.updates-block__body.is-active {
	display: block;
}
