.feature-carousel {
	position: relative;
	padding-top: var(--section-spacing-l);
	padding-bottom: var(--section-spacing-l);

	&:before {
		content: ' ';
		display: block;
		height: 60px;
		width: 60px;
		background: url('../images/white-curve.svg') no-repeat;
		z-index: 1;
		position: absolute;
		top: -15px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.feature-carousel__inner {

	@include mq(large) {
		position: relative;
		margin: 0 auto;
		padding: 0 var(--spacing);
		width: 100%;
		max-width: var(--container-width);
	}
}

.feature-carousel__title {
	color: var(--color-text-reversed);
	padding: 0 var(--spacing-l) var(--spacing-l);

	@include mq(large) {
		padding: 0;
		margin-bottom: var(--spacing-l);
	}
}

.feature-swiper {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.feature-slide.swiper-slide {
	width: 85%;

	@include mq(large) {
		width: 75%;
	}
}

.feature-slide__inner {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: var(--spacing-l);

	@include mq(large) {
		flex-direction: row;
		margin-left: 0;
	}
}

.feature-slide__media {
	flex-basis: 48%;
}

.feature-slide__title {
	margin: var(--spacing) 0;
	color: white;
}

.feature-slide__content {
	flex-basis: 48%;
	display: flex;
	flex-direction: column;
}

.feature-slide__quote {
	margin-top: auto;
	margin-bottom: var(--spacing);
	padding-left: 0;

	p {
		font-size: var(--font-size-h3);
		line-height: 1.3;
		margin: 0;
		color: white;
	}

	cite {
		font-size: var(--font-size-small);
		color: var(--color-light-grey);
		font-style: normal;
		text-transform: uppercase;
	}

	a {
		color: var(--color-light-grey);

		&:hover {
			color: var(--color-primary);
		}
	}
}

.feature-slide__link {
	text-transform: uppercase;
	color: var(--color-text-reversed);
	font-size: var(--font-size-small);
	text-decoration: underline;
}

.feature-swiper {
	.swiper-button-prev,
	.swiper-button-next {
		margin: 0;
		color: white;
		top: 0px;
		display: none;
	}

	.swiper-button-prev {
		right: 40px;
		left: auto;
	}

	.swiper-button-next {
		right: 0;
	}

	@include mq(large) {
		.swiper-button-prev,
		.swiper-button-next {
			display: flex;
		}
	}
}
