// DM Sans
//@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,700;1,400&display=swap');

// Setup
@import "settings";
@import "utilities/mq";
@import "utilities/mixins";
@import "variables";

// Base
@import "base/base";
@import "base/typography";
@import "base/figures";
@import "base/tables";
@import "base/forms";
@import "base/responsive-embeds";

// Components
@import "components/layout";
@import "components/content";
@import "components/icons";
@import "components/buttons";
@import "components/alert";
@import "components/cookie";
@import "components/global-header";
@import "components/global-nav-mobile";
@import "components/global-nav-desktop";
@import "components/global-search";
@import "components/language-selector";
@import "components/global-footer";
@import "components/social-links";
@import "components/breadcrumbs.scss";
@import "components/page-header";
@import "components/page-header-blog";
@import "components/page-header-article";
@import "components/page-header-about";
@import "components/page-header-methodology";
@import "components/page-header-comparison";
@import "components/page-header-user-reviews";
@import "components/page-header-ebook";
@import "components/page-header-home";
@import "components/page-header-domain-connector";
@import "components/hero-header";
@import "components/review-header";
@import "components/category-links";
@import "components/article";
@import "components/feature-panel";
@import "components/downloads-panel";
@import "components/team-panel";
@import "components/meet-the-tooltesters-panel";
@import "components/review-panel";
@import "components/feature-carousel";
@import "components/feature-image";
@import "components/card";
@import "components/icon-panel";
@import "components/blog";
@import "components/brand-logos";
@import "components/numbers-panel";
@import "components/what-can-you-find-panel.scss";
@import "components/what-you-will-find-panel.scss";
@import "components/who-wrote-this-panel.scss";
@import "components/review-process-panel.scss";
@import "components/ebook-text-panel.scss";
@import "components/rating-circle.scss";
@import "components/review-nav.scss";
@import "components/review-section-header.scss";
@import "components/review-author-panel.scss";
@import "components/accordion.scss";
@import "components/sidebar.scss";
@import "components/pricing-block.scss";
@import "components/tabs.scss";
@import "components/updates-block.scss";
@import "components/block.scss";
@import "components/comments.scss";
@import "components/star-rating.scss";
@import "components/rating-panel.scss";
@import "components/author-detail.scss";
@import "components/author-bio.scss";
@import "components/gallery.scss";
@import "components/contact-form.scss";
@import "components/video.scss";
@import "components/comparison.scss";
@import "components/landing-pages.scss";
@import "components/user-review-panel.scss";
@import "components/domain-connector.scss";
@import "components/examples.scss";
@import "components/user-review-form.scss";
@import "components/img-optimisation.scss";
@import "components/contact-form-cf7.scss";
@import "components/lazy-quiz.scss";

// Vendor
@import "vendor/hamburger";
@import "vendor/lity";
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/shift-away-subtle.css';

@import "utilities/helpers";

@media print { @import "media/print"; }
