.social-links {
	display: flex;
	justify-content: center;
}

.social-links__item {
	margin: 0 1rem;
}

.social-links__link {
	color: white;

	&:hover {
		color: white;
	}
}

.social-links__link .icon {
	width: 30px;
	height: 30px;
}
