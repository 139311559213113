.page-header--domain-connector {
	background: #fff;
	.page-header__inner {
		text-align: center;
		display: block;
		padding-top: var(--spacing-small);
		@include mq(medium) {
			padding-top: var(--spacing);
		}
		.page-header__content {
			margin: 0 auto;
			padding-top: var(--spacing);
			@include mq(medium) {
				max-width: 75%;
			}
			@include mq(large) {
				max-width: 50%;
			}
		}
	}

	.page-header__subhead {
		font-size: var(--font-size-small);
		text-transform: uppercase;
		margin-bottom: var(--spacing);
		color: var(--color-text-light);
	}

	.page-header__desc {
		color: var(--color-text-base);
		margin-top: var(--spacing-xl);
		color: var(--color-text-light);
		line-height: 1.4;
	}
}
