.stars {
	--percent: calc(var(--rating) / 5 * 100%);

	display: inline-block;
	font-size: 20px;
	font-family: var(--body-family);
	line-height: 1;

	&::before {
		content: '\2605\2605\2605\2605\2605';
		letter-spacing: 1px;
		background: linear-gradient(90deg, var(--color-grey) var(--percent), var(--color-lighter-grey) var(--percent));
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: var(--body-family);
	}
}

.stars--primary {
	&::before {
		content: '\2605\2605\2605\2605\2605';
		letter-spacing: 1px;
		background: linear-gradient(90deg, var(--color-primary) var(--percent), var(--color-primary-light) var(--percent));
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

.stars--medium {
	@include mq(medium) {
		font-size: 22px;
	}
}

.stars--large {
	@include mq(medium) {
		font-size: 26px;
	}
}
