.page-header--about {
	.page-header__inner {
		text-align: left;
		@include mq(medium) {
			display: grid;
		    grid-gap: var(--grid-gap);
		    grid-template-columns: 3fr 1fr;
			padding: var(--spacing-xxl);
			margin-top: var(--spacing-xxl);
		}
	}

	.page-header__title {
		font-size: var(--font-size-h1);
	}

	.page-header__subhead {
		font-size: var(--font-size-small);
		text-transform: uppercase;
		margin-bottom: var(--spacing);
		color: var(--color-text-light);
	}
	
}
