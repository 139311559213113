.block-category-links {
	background: var(--color-cream);
	padding-top: var(--spacing);
	padding-bottom: var(--spacing);
}

.block-category-links__inner {
	@extend .container;
}

.category-links {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	column-gap: var(--spacing-s);
	row-gap: var(--spacing);

	@include mq(large) {
		flex-wrap: no-wrap;
		gap: var(--spacing);
	}
}

.category-links__item {
	flex-basis: calc(50% - var(--spacing-s));

	@include mq(large) {
		flex: 1;
	}
}

.category-links__link {
	display: block;
	background: white;
	border-radius: var(--border-radius);
	padding: 0.75rem;
	box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);

	&:hover {
		text-decoration: none;
	}

	@include mq(medium) {
		padding: var(--spacing);
	}
}

.category-links__title {
	font-size: var(--font-size);
	font-weight: var(--font-weight-bold);

	@include mq(medium) {
		font-size: var(--font-size-lead);
	}
}

.category-links__arrow-right {
	color: var(--color-light-grey);
	margin-top: 5px;
	width: 14px;
	height: 14px;

	.category-links__link:hover & {
		color: inherit;
	}

	@include mq(medium) {
		width: 20px;
		height: 20px;
	}
}

.category-links__link--primary:hover {
	background: var(--color-primary);
	color: var(--color-text-base);
}

.category-links__link--secondary:hover {
	background: var(--color-secondary);
	color: var(--color-text-base);
}

.category-links__link--tertiary:hover {
	background: var(--color-tertiary);
	color: var(--color-white);
}

.category-links__link--quaternary:hover {
	background: var(--color-quaternary);
	color: var(--color-text-base);
}

.category-links__link--quinary:hover {
	background: var(--color-quinary);
	color: var(--color-text-base);
}
