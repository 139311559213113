article {
	background: #fff;
	padding: var(--spacing) 0;
}

//possibly in the wrong place
.user-avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
}

article .container {
	padding-bottom: var(--spacing-l);
	padding-top: 	var(--spacing-l);

	@include mq(large) {
		padding-bottom: var(--spacing-xxl);
		padding-top: 	var(--spacing-xxl);
	}
}

article .content {
	p:first-of-type {
		margin-top: 0;
	}

	#ez-toc-container { display: none; }
}

article .callout{

	background-color: #faf7f1;
	padding: var(--spacing-l);
    margin: var(--section-spacing) 0;
    max-width: var(--content-width-s);

	@include mq(large) {
		padding: var(--spacing-xxl);
	}
}

//for table of contents plugin
.toc-mobile {
	max-width: var(--content-width-s);

	@include mq(large) {
		display: none;
	}
}

div#ez-toc-container {
	border: 0;
	border-radius: 0;
	padding: 0;
	box-shadow: none;
	position: sticky;
	top: 30px;
	display: none;
	@include mq(large) {
		display: block;
	}
	.ez-toc-title {
		display: none;
	}

	ul.ez-toc-list {
		padding: 0;
		li {
			margin: var(--spacing-s) 0;
			position: relative;
			line-height: 1;
			list-style: none;
			&:before { display: none; }

			a {
				position: relative;
				display: block;
				padding: 0.3em 0;
				color: var(--color-text-light);
				font-size: var(--font-size-small);
				transition: all 0.3s ease;

				&:before {
					//!important to remove numerals from TOC plugin
					content: '' !important;
					position: absolute;
					top: 50%;
					left: 0;
					display: block;
					height: 8px;
					width: 8px;
					background: white;
					border-radius: 8px;
					transform: translateY(-50%);
					opacity: 0;
					transition: all 0.3s ease;
				}
				&:visited {
					color: var(--color-text-light);
				}
				&:hover {
					color: var(	--color-primary);
				}
			}

			&.is-active > a {
				color: var(	--color-primary);
				padding-left: 16px;

				&:before {
					background: var(--color-primary);
					opacity: 1;
				}
			}
		}
	}
}

ul.ez-toc-list-level-3{
	padding-left: 15px;
}