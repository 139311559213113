.page-header {
	background: var(--color-cream);
	padding-top: var(--spacing-xxl);
}

.page-header__inner {
	@extend .container;
	padding-top: var(--spacing-xxl);
	text-align: center;

	@include mq(large) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: left;
	}
}

.page-header__image {
	display: block;
	width: 100%;

	@include mq(large) {
		width: 45%;
	}

	@include mq(xxlarge) {
		width: 50%;
	}
}

// =============================================================================
// Default for page.php
// =============================================================================

.page-header--default {
	.page-header__inner {
		@extend .container-width-xs;
		padding-bottom: var(--spacing-l);
		.page-header__content { text-align: left; }

		h1 {
			margin-bottom: var(--spacing);
		}
	}
	.breadcrumbs {
		margin: 0 auto var(--spacing);
	    padding: 0.725rem 1.3em 0;
	    width: 100%;
	    max-width: var(--container-width);
	}
}

// =============================================================================
// Centered
// =============================================================================

.page-header--centered .page-header__inner {
	justify-content: center;
	text-align: center;
	padding-top: var(--spacing-xxl);
	padding-bottom: var(--spacing-xxl);
}
