.page-header--comparison {
	.page-header__inner {
		text-align: center;
		@include mq(medium) {
			height: 250px;
			display: flex;
			flex-flow: column;
			justify-content: center;
		}
		@include mq(large) {
			height: 350px;
		}

		h1 {
			width: 100%;
			margin-top: var(--spacing-l);
			@include mq(medium) { margin-top: 0; }
		}

		.comparison__disclaimer {
			@include mq(medium) {
				margin: 0 auto;
			    width: 100%;
			    max-width: var(--container-width-xs);
			}
		}
	}
}