.author-panel {
	box-shadow: 0px 0px 18px 1px rgba(0, 0, 0, 0.04);
	padding: var(--spacing);
	border-radius: var(--border-radius);

	@include mq(large) {
		display: flex;
		align-items: flex-start;
	}
}

.review-section-header + .author-panel {
	margin: var(--section-spacing) 0 0;
}

.author-panel__content p {
	margin: 0;
	font-size: var(--font-size-small);
	padding-right: var(--spacing);
}

.author-panel__content a {
	display: inline-block;
	color: var(--color-primary);
	text-transform: uppercase;
	text-decoration: underline;
	font-weight: var(--font-weight-bold);
	font-size: var(--font-size-xsmall);
	margin: var(--spacing) 0;

	svg {
		height: 10px;
		width: 10px;
		margin-left: 5px;
		margin-top: -2px;
	}

	@include mq(large) {
		margin: 0;
	}
}

.author-panel__group {
	flex: 0 0 50%;
	display: flex;
	justify-content: flex-start;
	margin-top: var(--spacing);

	@include mq(large) {
		margin-top: 0;
		justify-content: flex-end;
	}
}

.author-card {
	display: flex;
	flex: 0 0 50%;

	img {
		display: block;
		border-radius: 8px;
	}
}

.author-card__media {
	margin-right: 5px;
	flex: 0 0 60px;

	.user-avatar {
		width: 60px;
		height: 60px;
	}

	@include mq(large) {
		flex: 0 0 80px;

		.user-avatar {
			width: 80px;
			height: 80px;
		}
	}
}

.author-card__text {
	flex: 1;
}

.author-card__author,
.author-card__role {
	font-size: var(--font-size-xsmall);
	font-weight: var(--font-weight-bold);
	margin: 0;
	line-height: 1.2;
}

.author-card__author {
	display: flex;
	align-items: center;
}

.author-card__author a {
	color: var(--color-text-base)!important;
}

.author-card__role {
	font-weight: var(--font-weight-normal);
	color: var(--color-text-light);
}


// Author checkmark
.author-checkmark {
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--color-primary);
	height: 15px;
	width: 15px;
	border-radius: 10px;
	margin-left: 6px;
	color: white;
	flex-shrink: 0;
}

.author-checkmark--inactive {
	background: var(--color-light-grey);
}

.author-checkmark svg {
	height: 10px;
	width: 10px;
}
