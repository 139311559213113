.feature-panel {
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);
}

.feature-panel__inner {
	@extend .container;

	@include mq(large) {
		display: grid;
		grid-gap: var(--grid-gap);
		grid-template-columns: 1fr 1fr;
		align-items: center;
	}
}

.feature-panel--right {
	.feature-panel__media {
		order: 2;
	}

	.feature-panel__content {
		order: 1;
	}
}

.feature-panel__media {
	grid-column: 1;
	position: relative;
	margin-bottom: var(--spacing-xl);

	@include mq(large) {
		margin-bottom: 0;
	}
}

.feature-panel__content {
	grid-column: 2;
	text-align: left;

	@include mq(large) {
		margin-bottom: 0;
	}
}

.image-swiper {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.image-slide.swiper-slide {
	width: 100%;
}

.image-swiper-button-prev,
.image-swiper-button-next {
	position: absolute;
	top: 50%;
	z-index: z-scale(foreground);
	opacity: 0;
	transition: opacity 0.1s ease-in-out;
}

.image-swiper-button-prev {
	left: 0;
}

.image-swiper-button-next {
	right: 0;
}

.image-swiper:hover {
	.image-swiper-button-prev,
	.image-swiper-button-next {
		opacity: 1;
	}
}

.swiper-button-disabled.image-swiper-button-prev,
.swiper-button-disabled.image-swiper-button-next {
	opacity: 0!important;
}
