.hero-header {
	background: var(--color-cream);
	padding-top: var(--spacing-xxl);
	padding-bottom: var(--spacing);
}

.hero-header__inner {
	@extend .container;
	padding-top: var(--spacing-xxl);
	text-align: center;

	@include mq(large) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-align: left;
	}
}

.hero-header__content {
	@include mq(large) {
		flex-basis: 50%;
	}
}

.hero-header__media {
	margin-top: var(--spacing-l);

	@include mq(large) {
		flex-basis: 45%;
		margin-top: 0;
	}
}

.hero-header__image {
	display: block;
	width: 100%;
}
