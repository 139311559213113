.global-header__nav {
	flex: 1;
}

.global-nav {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: var(--font-size-small);
}

// =============================================================================
// Top Level
// =============================================================================
.global-nav--level-0 > .global-nav__item {
	> .global-nav__link {
		font-weight: var(--font-weight-bold);
		padding: 1rem 0;
		margin: 0 0.7rem;
		border-bottom: 4px solid transparent;
	}

	&.is-active > .global-nav__link {
		border-bottom: 4px solid var(--color-primary);
		text-decoration: none;
		color: var(--color-text-base);
	}

	> .global-nav__link .global-nav__item-next {
		margin-left: 2px;
	}

	.global-nav__link:hover .global-nav__item-expand {
		display: none;
	}

	> .global-nav__link svg {
		width: 10px;
		height: 10px;
	}

	@include mq(xlarge) {
		> .global-nav__link {
			margin: 0 1rem;
		}
	}

	@include mq(xxlarge) {
		> .global-nav__link {
			margin: 0 1.5rem;
		}
	}
}



// =============================================================================
// Dropdown
// =============================================================================
.global-nav__group {
	display: none;
	position: absolute;
	width: 100%;
	max-width: 1400px;
	left: 50%;
	transform: translateX(-50%);
	padding: 0 var(--spacing);

	&.is-active {
		display: block;
	}
}

.global-nav__group-inner {
	background: white;
	max-width: 1400px;
	margin: 0 auto;
	display: flex;
	border-radius: var(--border-radius);
	box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.14);
	margin-top: 1rem;
}

// Fix tools submenu height to prevent links from wrapping
.global-nav__item.tools .global-nav__group-inner {
	min-height: 600px;
}

.global-nav__featured {
	padding: var(--spacing-l);
	flex-basis: 33%;
	border-right: 1px solid var(--color-nav-grey);
}

.global-nav__sub-nav {
	position: relative;
	padding: var(--spacing-l);
	flex-basis: 67%;
}

// force menu open for dev
// .global-nav__item:first-child .global-nav__group {
// 	display: block;
// }

.global-nav__item.global-nav__item--title {
	text-transform: uppercase;
	font-size: var(--font-size-small);
	color: var(--color-text-alt);
	margin-bottom: 5px;
}



// =============================================================================
// Featured post styling
// =============================================================================
.global-nav__item.is-featured {
	margin-bottom: var(--spacing);

	.global-nav__link {
		display: block;
		padding: var(--spacing);
		background: var(--color-cream);
		font-size: var(--font-size-h4);
		font-weight: var(--font-weight-bold);
		line-height: var(--line-height-headings);
		border-radius: var(--border-radius);
		transition: background 0.1s ease-in-out;

		.global-nav__link-litle {
			display: block;
			font-size: var(--font-size-xsmall);
			text-transform: uppercase;
			margin-bottom: var(--spacing-s);
		}

		&:hover {
			text-decoration: none;
			color: var(--color-text-base);
			background: var(--color-cream-dark);
		}
	}
}



// =============================================================================
// Level 1 nav
// =============================================================================
ul.global-nav--level-1 {
	width: 40%;

	> .global-nav__item {
		width: 100%;
	}

	> .global-nav__item > .global-nav__link {
		display: flex;
		justify-content: space-between;
		border-radius: var(--border-radius);
		padding: 4px 10px;
		margin-right: 10px;

		&:hover {
			background: var(--color-primary-light);
			color: var(--color-text-base);
			text-decoration: none;
		}

		svg {
			width: 12px;
			height: 12px;
		}
	}

	&:after {
		content: ' ';
		position: absolute;
		left: calc(40% + var(--spacing));
		top: 0;
		display: block;
		background: var(--color-nav-grey);
		width: 1px;
		height: 100%;
		z-index: z-scale(foreground);
		pointer-events: none;
	}
}



// =============================================================================
// Level 2 nav
// =============================================================================
ul.global-nav--level-2 {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 60%;
	background: white;
	padding-top: var(--spacing-l);
	padding-left: 2.5rem;
	padding-right: var(--spacing-l);
	display: none;
	z-index: -1;

	.global-nav__item {
		width: 100%;
	}

	.global-nav__item.global-nav__item--title {
		margin-top: var(--spacing);
	}

	.global-nav__link {
		display: block;
		padding: var(--spacing);
		margin-bottom: var(--spacing);
		background: var(--color-cream);
		font-weight: var(--font-weight-bold);
		line-height: var(--line-height-headings);
		border-radius: var(--border-radius);
		transition: background 0.1s ease-in-out;

		.global-nav__link-litle {
			display: block;
			margin-bottom: var(--spacing-s);
			font-weight: var(--font-weight-normal);
		}

		&:hover {
			text-decoration: none;
			color: var(--color-text-base);
			background: var(--color-cream-dark);
		}
	}

	.global-nav__item.is-related {
		border-bottom: 1px solid var(--color-nav-grey);

		&:last-child {
			border-bottom: 0;
		}

		.global-nav__link {
			background: white;
			margin: 0;
			padding-left: 0;
			padding-right: 0;
			border-radius: 0;
		}
	}
}

.global-nav--level-1 .global-nav__item:hover .global-nav__child {
	display: block;
	z-index: z-scale(foreground);
}

ul.small-menu .global-nav__link {
	margin-bottom: var(--spacing);
    padding: var(--spacing-s);		
}

// =============================================================================
// Colours
// =============================================================================
.global-nav--level-0 {
	> .ecommerce.global-nav__item.is-active > .global-nav__link {
		border-bottom: 4px solid var(--color-secondary);
	}

	> .hosting.global-nav__item.is-active > .global-nav__link {
		border-bottom: 4px solid var(--color-tertiary);
	}

	> .tools.global-nav__item.is-active > .global-nav__link {
		border-bottom: 4px solid var(--color-primary);
	}

	> .email.global-nav__item.is-active > .global-nav__link {
		border-bottom: 4px solid var(--color-quaternary);
	}

	> .crm.global-nav__item.is-active > .global-nav__link {
		border-bottom: 4px solid var(--color-quinary);
	}
}

.global-nav--level-1 > .global-nav__item > .global-nav__link:hover,
.global-nav--level-1 > .global-nav__item:hover > .global-nav__link {
	.website-builders & {
		background: var(--color-primary-light);
	}

	.ecommerce & {
		background: var(--color-secondary-light);
	}

	.hosting & {
		background: var(--color-tertiary-light);
	}

	.email & {
		background: var(--color-quaternary-light);
	}

	.tools & {
		background: var(--color-primary-light);
	}

	.crm & {
		background: var(--color-quinary-light);
	}
}
