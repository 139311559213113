.back-to-top {
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    display: none;
    align-items: center;
    justify-content: center;
    background: var(--color-light-grey);
	color: white;
    color: white;
    height: 40px;
    width: 40px;
    border-radius: 40px;
    opacity: 0;
	z-index: z-scale(back-to-top);
    visibility: hidden;
    transition: all 0.2s ease;

    @include mq(medium) {
        display: flex;
    }

    svg {
        height: 18px;
        width: 18px;
    }

    &:hover {
        color: white;
    }

    &.is-visible {
        opacity: 1;
        visibility: visible;
    }

	// adjust position for cookie bar
	.cookies-not-set & {
		bottom: 100px;
	}
}

.global-footer {
	position: relative;
	background-color: var(--color-grey);
	margin-top: auto;
	padding: 4rem var(--spacing) var(--spacing);

	p {
		color: white;
		margin: 0;
		font-size: var(--font-size-small);
	}

	&:before {
		content: ' ';
		display: block;
		height: 100px;
		width: 100px;
		background: url('../images/white-curve.svg') no-repeat;
		z-index: 1;
		position: absolute;
		top: -25px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.u-bg-cream + .global-footer:before,
.has-comments + .global-footer:before {
	background: url('../images/cream-curve.svg') no-repeat;
}

.global-footer__inner {
	@extend .container;
}

.global-footer__primary {
	text-align: center;

	@include mq(large) {
		display: flex;
		flex-wrap: no-wrap;
		justify-content: space-between;
		text-align: left;
	}
}

.global-footer__nav {
	margin: var(--spacing) 0;

	@include mq(large) {
		order: 2;
		flex-basis: 30%;
		margin-left: 5%;
	}
}

.footer-nav {
	a {
		display: block;
		color: white;
		padding: var(--spacing-xs) 0;
		white-space: nowrap;
	}

	@include mq(medium) {
		columns: 2;
	}
}

.global-footer__social {
	margin: var(--spacing-l) 0;

	@include mq(large) {
		order: 3;
		flex-basis: 10%;
	}
}

.global-footer__newsletter {
	position: relative;
	margin: var(--spacing-l) 0;

	// #mc_embed_signup {
	// 	margin-bottom: var(--spacing-l);
	// }

	// label {
	// 	display: none;
	// }

	// input[type=email], input[type=text] {
	// 	width: 100%;
	// 	padding: 0;
	// 	background: transparent;
	// 	border: 0;
	// 	border-bottom: 1px solid white;
	// 	border-radius: 0;
	// 	font-size: var(--font-size-lead);
	// 	padding: 10px 0;
	// }

	// input[type=submit] {
	// 	position: absolute;
	// 	top: 10px;
	// 	right: 0;
	// 	background: url('../images/arrow-white.svg') no-repeat center right;
	// 	background-size: 40%;
	// 	color: transparent;
	// 	border-radius: 0;
	// 	border: 0;
	// 	margin: 0;
	// 	padding: 0;
	// }

	// .mc-field-group {
	// 	@include mq(medium) {
	// 		display: grid;
	// 		grid-template-columns: 1fr 1fr;
	// 		grid-gap: var(--grid-gap-sm);
	// 		grid-row-gap: 0;
	// 	}
	// }

	// #mc_embed_signup div.mce_inline_error {
	// 	border: 1px solid #6B0505;
	// 	border-radius: var(--border-radius);
	// 	background-color: var(--color-grey);
	// 	margin: 10px 0 0 0;
	// 	font-size: var(--font-size-xsmall);
	// 	font-weight: var(--font-weight-normal);
	// }

	@include mq(large) {
		order: 1;
		flex-basis: 45%;
	}
}

.global-footer__logo {
	color: white;
	width: 170px;
	height: auto;
	max-height: 30px;

	@include mq(large) {
		height: auto;
		width: 340px;
		max-height: 60px;
		// margin-top: var(--spacing-l);
	}
}

.footer-custom-logo{
	display: inline-block;
	max-width: 170px;
	@include mq(large){
		max-width: 340px;
	}
}

.global-footer__secondary {
	margin-top: var(--spacing);
	text-align: center;

	p {
		color: var(--color-light-grey);
		font-size: var(--font-size-small);
	}

	@include mq(large) {
		text-align: right;
		margin-top: var(--spacing-xl);
	}
}
