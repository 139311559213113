.user-reviews-header {
	padding-top: var(--spacing-xxl);
}

.user-reviews-header__inner {
	@extend .container;
    padding-top: var(--spacing-l);
	padding-bottom: var(--spacing-xxl);
}

.user-reviews-header__breadcrumbs-wrap {
	margin: 0 auto;
	padding: 0 1em;
	width: 100%;
	max-width: var(--container-width);
	padding-top: var(--spacing-l);
	@include mq(medium-major) {
		padding-top: 0.725rem;
	}

	p { margin: 0; }
}

.user-reviews-header__title {
	text-align: center;
	font-weight: var(--font-weight-bold);

	span {
		font-size: var(--font-size-h2);
		font-weight: normal;
		display: block;
	}
}

.user-reviews-header__image {
	margin: 0 auto;
}
