.language-selector--desktop {
	display: none;

	@include mq(large) {
		display: block;
		// position: relative;
	}
}

// .language-selector--desktop .language-selector__select {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// }

.language-selector__select {
	background: transparent;
	background: url(../images/chevron-down.svg) no-repeat right center;
	border: 0;
	color: var(--color-text-base);
	padding-right: 15px;
	overflow-y: auto;
	-webkit-appearance: none;
	appearance: none;
	outline: none;
}
