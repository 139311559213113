.page-header--ebook {
	text-align: center;
	padding-top: var(--spacing-xxl);
	padding-bottom: var(--section-spacing-l);
	@include mq(medium) {
		text-align: left;
		padding-top: var(--section-spacing-l);
	}

	.form {
		text-align: left;

		.btn {
		    @include mq($until: medium) {
				width: 80%;
			    display: block;
			    margin: 1.5em auto;
		    }
		}
	}
}