.gallery-swiper {
	height: auto;
	width: 100%;
	margin-top: var(--spacing);
}

.gallery-thumbs-swiper {
	height: 20%;
	box-sizing: border-box;
	margin: var(--spacing) 0;
}

.gallery-thumbs-swiper .swiper-slide {
	width: 125px;
	border: 1px solid #999;
	opacity: 0.4;
	margin: 5px;
}

.gallery-thumbs-swiper .swiper-slide-thumb-active {
	opacity: 1;
}

// Styling for the shortcode gallery
// [gallery columns="4" link="file" ids="45908"]
.gallery {
	margin-top: var(--spacing)!important;
	margin-bottom: var(--spacing)!important;

	.gallery-item img {
		margin-left: auto;
		margin-right: auto;
		border: 2px solid var(--color-nav-grey)!important;
		padding: 0.25rem;

		&:hover {
			border: 2px solid var(--color-lighter-grey)!important;
		}
	}

	.gallery-caption {
		font-size: var(--font-size-small);
		margin-top: 5px;
	}
}

.gallery.gallery-columns-2,
.gallery.gallery-columns-3,
.gallery.gallery-columns-4,
.gallery.gallery-columns-5,
.gallery.gallery-columns-6 {
	.gallery-item {
		@include mq($until: medium) {
			width: 50%!important;
		}
	}
}
