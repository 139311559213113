.landing-disclaimer{
	margin: 0 0 var(--spacing-xl);
}
.round-borders .panel-site-review {
	max-width: var(--content-width);
	margin-top: var(--spacing-xl);
	margin-bottom: var(--spacing-xl);
	border: 1px solid var(--color-nav-grey);
	border-radius: var(--border-radius);
	padding: var(--spacing);

	p:first-of-type {
		margin-top: var(--spacing-l);
	}

	p {
		max-width: var(--content-width);
	}

	img {
		margin: var(--spacing) auto;
	}

	h2, h3 {
		margin-top: var(--spacing);
		max-width: none;
		text-align: center;
	}

	.panel-footer {
		text-align: center;
	}
}

.content .no-border, .content .bordered {
	p:first-of-type {
		margin-top: var(--spacing-l);
	}
}
