.numbers-panel {
	padding-top: var(--section-spacing-l);
	padding-bottom: var(--section-spacing);
}

.numbers-panel__inner {
	@extend .container;
}

.numbers-panel__header {
	margin-bottom: var(--spacing-l);
	text-align: center;

	@include mq(medium-major) {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: var(--spacing-xxl);
	}
}

.number-grid {
	display: grid;
	grid-gap: var(--spacing);
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(5, 140px);

	@include mq(large) {
		grid-gap: var(--spacing-l);
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(4, 140px);
	}
}

.number-grid__item {
	background: darkgrey;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: var(--color-text-reversed);
	border-radius: var(--border-radius);
	padding: 0 var(--spacing);
}

.number-grid__item--1 {
	background-color: var(--color-quinary);
	grid-column: 1;
	grid-row: 1;
}

.number-grid__item--2 {
	grid-column: 2;
	grid-row: 1 / 3;

	@include mq(large) {
		grid-column: 2 / 4;
		grid-row: 1 / 4;
	}
}

.number-grid__item--3 {
	background-color: var(--color-primary);
	grid-column: 1 / 2;
	grid-row: 2;

	@include mq(large) {
		grid-column: 3;
		grid-row: 4;
	}
}

.number-grid__item--4 {
	grid-column: 1 / 3;
	grid-row: 3;

	@include mq(large) {
		grid-column: 1 / 3;
		grid-row: 4;
	}
}

.number-grid__item--5 {
	grid-column: 1;
	grid-row: 4 / 6;

	@include mq(large) {
		grid-column: 4 / 6;
		grid-row: 2 / 5;
	}
}

.number-grid__item--6 {
	background-color: var(--color-secondary);
	grid-column: 2;
	grid-row: 4;

	@include mq(large) {
		grid-column: 6;
		grid-row: 4;
	}
}

.number-grid__item--7 {
	background-color: var(--color-quaternary);
	grid-column: 2;
	grid-row: 5;

	@include mq(large) {
		grid-column: 6;
		grid-row: 1;
	}
}

.number-grid__stat {
	font-size: 2rem;
	line-height: 1;
	font-weight: var(--font-weight-bold);
	margin: 0;

	@include mq(xlarge) {
		font-size: 3rem;
	}
}


.number-grid__suffix {
	font-size: var(--font-size);
	margin-top: 0.5em;
	line-height: 1;

	@include mq(xlarge) {
		font-size: var(--font-size-lead);
	}
}
