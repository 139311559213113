.contact-form7{

    .single-article__content{
        width: var(--content-width);
        margin: 0 auto;
        max-width: 100%;
    }

    textarea{

        min-height: 200px;

    }
    
}