body.nav-open {
	overflow: hidden;
}

body.nav-open .global-header__inner {
	background: white;
	border-bottom: 1px solid var(--color-nav-grey);
}

.global-nav-mobile {
	position: fixed;
	top: 0;
	right: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	touch-action: none;
	z-index: z-scale(navigation);
	background: white;
	transform: translate3d(100%,0,0);
	transition: transform .3s ease;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;

	@include mq(large) {
		display: none;
	}
}

.global-nav-mobile.is-active {
	transform: translateZ(0);
}

.global-nav-mobile__inner {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	min-height: -webkit-fill-available;
}

.global-nav-mobile__body {
	flex: 1;
	padding: var(--spacing-l) var(--spacing);
}

.global-nav-mobile__footer {
	margin-top: auto;
	padding: var(--spacing-l) var(--spacing);
}

// =============================================================================
// Nav
// Logic for opening/closing menus
// =============================================================================

ul.nav-mobile--level-0,
ul.nav-mobile--level-1 {
    position: absolute;
    left: calc(100vw);
    top: 0;
    width: calc(100vw);
    height: 100%;
    margin: 0;
    padding: var(--spacing-xxxl) var(--spacing) var(--spacing);
    transform: translateZ(0);
    transition: transform .3s ease;
    z-index: 1;
    background-color: white;
    -webkit-overflow-scrolling: touch;
}

ul.nav-mobile--level-0 {
	left: 0;
}

ul.nav-mobile--level-1 {
	height: 0;
	overflow-x: hidden;
}

ul.nav-mobile--level-0.is-active {
	transform: translate3d(calc(-100vw),0,0);
}

.nav-mobile__item.is-active > .nav-mobile--level-1 {
	z-index: 2;
	height: auto;
	overflow-x: scroll;

	// &:after {
	// 	content: '';
	// 	position: absolute;
	// 	left: 0;
	// 	right: 0;
	// 	top: 90vh;
	// 	height: 400px;
	// 	width: 100%;
	// 	background: blue;
	// 	z-index: -1;
	// }
}

.nav-mobile--level-2 {
	display: none;
	width: 100%;
}

.nav-mobile__item.is-active > .nav-mobile--level-2 {
	display: block;
}

// =============================================================================
// Nav
// General styling
// =============================================================================

.nav-mobile {
	display: flex;
	flex-direction: column;
}

.nav-mobile__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	border-top: 1px solid var(--color-nav-grey);

	&:first-child {
		border-top: 0;
	}
}

ul.nav-mobile--level-2 {
	background-color: var(--color-cream);
	margin-left: -1rem;
	margin-right: -1rem;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 1rem;
	width: calc(100% + 2rem);
}

.nav-mobile--level-2 .nav-mobile__item {
	border: 0;
	padding-left: 1rem;
	padding-right: 1rem;
}

.nav-mobile__link {
	flex: 1;
	padding: 0.5rem;
	font-weight: var(--font-weight-bold);
}

.nav-mobile__item-inner {
	display: flex;
	flex: 1;
	position: relative;
}

.nav-mobile__label {
	padding: 0.5rem;
	font-weight: var(--font-weight-bold);
	z-index: 1;
}

.nav-mobile__item-next,
.nav-mobile__item-expand {
	padding: 4px 10px;

	svg {
		height: 14px;
		width: 14px;
	}
}

// On the top level, the arrow expands over the full link
.nav-mobile__item-next--full {
	display: flex;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
	z-index: 2;

	svg {
		margin-left: auto;
	}
}



.nav-mobile__item.is-active > .nav-mobile__item-expand {
	transform: rotate(180deg);
}

.nav-mobile__item.nav-mobile__item--title {
	justify-content: flex-start;
	border-top: 0;
	text-transform: uppercase;
	// font-size: var(--font-size-small);
	font-weight: var(--font-weight-bold);
	color: var(--color-text-alt);
	margin: 20px 0 10px 0;

	svg {
		margin-right: 8px;
		width: 14px;
		height: 14px;
	}
}

.nav-mobile--level-1 > .nav-mobile__item.is-featured {
	border: 0;
	margin-bottom: 1rem;

	.nav-mobile__link {
		flex: 1;
		background: var(--color-cream);
		border-radius: var(--border-radius);
		padding: 0.8rem 1.2rem;
		line-height: 1.3;
	}

	.nav-mobile__link-litle {
		display: block;
		text-transform: uppercase;
		font-size: var(--font-size-small);
		margin-bottom: 8px;
	}
}

.nav-mobile--level-2 > .nav-mobile__item {
	border: 0;
	margin-top: 1rem;

	.nav-mobile__link {
		border-left: 2px solid var(--color-primary);
		margin-left: 10px;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		line-height: 1.2;

		.ecommerce & {
			border-left: 2px solid var(--color-secondary);
		}

		.hosting & {
			border-left: 2px solid var(--color-tertiary);
		}

		.tools &,
		.email & {
			border-left: 2px solid var(--color-quaternary);
		}

		.crm & {
			border-left: 2px solid var(--color-quinary);
		}
	}

	.nav-mobile__link-litle {
		display: block;
		font-weight: var(--font-weight-normal);
	}
}

.nav-mobile--level-2 > .nav-mobile__item.is-related {
	.nav-mobile__link {
		border-left: 0;
		margin-left: 0;
		padding-top: 0;
		padding-bottom: 0;
	}
}

.nav-mobile__item--back {
	justify-content: flex-start;
	padding: 0.5rem;
	font-weight: var(--font-weight-bold);

	svg {
		margin-right: 8px;
		width: 14px;
		height: 14px;
	}
}

.nav-mobile__item--search {
	padding-top: 2rem;

	.search-form {
		position: relative;
		width: 100%;
		border-radius: 40px;
		border: 1px solid var(--color-lightest-grey);
	}

	.search-form__input {
		width: 100%;
		border: 0;
		background: transparent;
		padding: 10px 10px 10px 50px;

		&:focus {
			outline: none;
		}
	}

	.search-form__submit {
		position: absolute;
		left: 10px;
		top: 50%;
		background: transparent;
		border: 0;
		transform: translateY(-50%);
		color: var(--color-lightest-grey);

		span {
			@extend .sr-only;
		}
	}
}

.nav-mobile__item--language-selector {
	margin-top: auto;
	border-top: 0;
}
