.brand-logos {
	padding-top: var(--section-spacing-l);
	padding-bottom: var(--section-spacing-l);
}

.brand-logos__title {
	text-align: center;
	text-transform: uppercase;
	font-size: var(--font-size-lead);
	margin-bottom: var(--spacing-l);
	font-weight: var(--font-weight-bold);
}

.brand-logos__grid {
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-wrap: wrap;

	img {
		margin: var(--spacing);
	}
}
