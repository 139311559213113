.meet-the-tooltesters-panel {
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);
	overflow: hidden;
	text-align: center;
	@include mq(medium) {
		text-align: left;
	}
}

.meet-the-tooltesters__intro {
	@include mq(medium) {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.meet-the-tooltesters__title {
			margin-bottom: var(--spacing);
		}
	}
}

.meet-the-tooltesters__link {
	border-bottom: 1px solid var(--color-text-light);
	text-transform: uppercase;
	font-size: var(--font-size-small);
	font-weight: var(--font-weight-bold);
	transition: color 0.1s ease-in-out, border 0.1s ease-in-out;
	&:after {
		content: '';
		width: 10px;
		height: 10px;
		background: url("data:image/svg+xml,%3Csvg width='9' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.34 0 9 3.925v.15L5.34 8l-.925-.94 2.262-2.345H0V3.298h6.69L4.414.94 5.339 0Z' fill='%231A1C22'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
		display: inline-block;
		margin-left: 5px;
		transition: margin 0.1s ease-in-out;
	}
	&:hover, &:focus {
		text-decoration: none;
		color: var(--color-primary);
		border-color: var(--color-primary);
		&:after {
			background: url("data:image/svg+xml,%3Csvg width='9' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.34 0 9 3.925v.15L5.34 8l-.925-.94 2.262-2.345H0V3.298h6.69L4.414.94 5.339 0Z' fill='%2357C0F9'/%3E%3C/svg%3E") no-repeat center center;
			background-size: contain;
		}
	}
}


.meet-the-tooltesters__image {
	margin-bottom: var(--spacing-xl);
	max-width: 300px;
	display: inline-block;
}

#show-more {
	visibility: hidden;
	opacity: 0;
	height: 0;
	transition: visibility 0.35s ease-in-out, opacity 0.35s ease-in-out, height 0.35s ease-in-out;

	&.is-active {
		visibility: visible;
		opacity: 1;
		height: auto;
	}
}

.meet-the-team-swiper {
	.swiper-slide {
		width: auto;
	}
	.team-swiper__nav {
		width: 80px;
    	@include mq(medium) {
    		display: flex;
	    	justify-content: space-between;
	    }
		.swiper-button-next, .swiper-button-prev {
			position: relative;
		    top: initial;
		    right: inherit;
		    left: inherit;
		    margin-top: 0;
		    color: var(--color-text-base);
		}
	}
}