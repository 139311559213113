.block-blog-articles {
	padding-top: var(--spacing-l);
	padding-bottom: var(--spacing-l);
	@include mq(large) {
		padding-top: var(--section-spacing-l);
		padding-bottom: var(--section-spacing-l);
	}

	//hide images after 12th item
	//.grid .grid__cell:nth-child(n+13) {
	//	.card__header { display: none; }
	//	.card__footer { padding-top: var(--spacing-l); }
	//}
}

.load__more {
	text-align: center;
	margin-top: var(--section-spacing);
	a {
		@extend .btn;
	}
}

// .post__category--e-commerce-2 a {
// 	&:hover { color: var(--color-secondary); }
// }

// .post__category--general-2 a {
// 	&:hover { color: var(--color-primary); }
// }

// .post__category--hosting {
// 	&:hover { color: var(--color-tertiary); }
// }
