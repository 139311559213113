.accordion {
	margin-top: var(--section-spacing);
	margin-bottom: var(--section-spacing);
}

.accordion h2 {
	margin-bottom: var(--section-spacing);
}

.accordion__item {
	border-top: 1px solid var(--color-lightest-grey);

	&:last-child {
		border-bottom: 1px solid var(--color-lightest-grey);
	}
}

.accordion__handle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	text-align: left;
	margin: 0;
	padding: var(--spacing) 0;
	border: 0;
	background: transparent;
	color: var(--color-text-base);
	cursor: pointer;

	@include mq(medium) {
		padding: var(--spacing-l) 0;
	}
}

.accordion__title {
	margin: 0!important;
	color: var(--color-text-dark);
	font-size: var(--font-size-h4);
	font-weight: var(--font-weight-normal);
	flex: 1;
}

.accordion__icon {
    position: relative;
    width: 20px;
    height: 20px;
	margin: 0 var(--spacing);

    &:before,
    &:after {
        content: "";
        position: absolute;
        background: var(--color-text-base);
        transition: transform 0.25s ease-out;
    }

    &:before {
        top: 0;
        left: 50%;
        width: 2px;
        height: 100%;
        margin-left: -1px;
    }

    &:after {
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        margin-top: -1px;
    }

	.is-active & {
        &:before { transform: rotate(90deg); }
        &:after { transform: rotate(180deg); }
    }
}

.accordion__body {
	display: none;

	&.is-open {
		display: block;
		margin-bottom: var(--spacing-l);
	}
}

// Single styling, used for the expandable-section shortcode
.accordion--single {
	.accordion__handle {
		border-bottom: 0;
	}

	.accordion__item {
		border-bottom: 1px solid var(--color-lightest-grey);
		border-top: 1px solid var(--color-lightest-grey);
		max-width: var(--content-width-s);
	}

	.accordion__title {
		font-weight: bold;
		font-size: var(--font-size);
	}
}
