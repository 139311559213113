.review-nav {
	display: flex;
	border-bottom: 1px solid var(--color-nav-grey);
}

.review-nav__link {
	display: block;
	font-size: var(--font-size-lead);
	padding: var(--spacing-s);
	border-bottom: 2px solid white;

	&:hover {
		text-decoration: none;
		color: var(--color-text-base);
		border-bottom: 2px solid var(--color-text-base);
	}
}

.review-nav__link--active {
	font-weight: bold;
	border-bottom: 2px solid var(--color-text-base);
}
