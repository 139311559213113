.youtube-embed {
	margin-top: var(--spacing-l);
}

.youtube-embed + p {
	margin-top: var(--spacing-s);
}

.youtube {
	background-color: #000;
    overflow: hidden;
    cursor: pointer;
}

.youtube img {
	position: absolute;
	width: 100%!important;
    padding: 0!important;
    margin-top: 0!important;
    margin-bottom: 0!important;
    left: 0;
    opacity: .7;
    top: 50%;
    transform: translateY(-50%);
}

.play-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 70px;
	height: 70px;
	border-radius: 35px;
	transform: translate(-50%, -50%);
	background: var(--color-primary);
	z-index: 1;
}

.play-button--small {
	width: 40px;
	height: 40px;

	svg {
		height: 14px;
		width: 14px;
	}
}

// Add additional spacing on blog pages
.single-post,
.page-template-template-like-a-blog {
	.embed-responsive {
		margin-top: var(--spacing);
	}
}

// Remove p tags added by WordPress
.embed-responsive p,
.youtube p {
	display: none;
}

// Popup {
.youtube-popup {
	display: inline-block;
	position: relative;
	margin: var(--spacing) 0;

	a {
		display: inline-block;
		background: black;
	}

	img {
		opacity: .7;
	}
}
