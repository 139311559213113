.review-process-panel {
	text-align: center;
	padding-top: var(--section-spacing);
	@include mq(medium) {
		text-align: left;
	}
	@include mq(large) {
		padding-top: var(--section-spacing-l);
	}
	.review-process__header {
		text-align: center;
		margin-bottom: var(--spacing-l);
		@include mq(medium) {
			margin-bottom: var(--spacing-xxl);
		}
	}

	.review-process__steps {
		display: grid;
		grid-template-columns: 1fr;
		@include mq(medium) { grid-template-columns: 1fr 1fr 1fr; }
		@include mq(large)  { grid-template-columns: 1fr 1fr 1fr 1fr 1fr; }

		.review-process__step {
			text-align: center;
			margin-bottom: var(--section-spacing);

			.review-process__content {
				padding: 0 var(--grid-gap-sm);
				@include mq(large) { padding: 0 var(--spacing); }
				h3,
				h5 {
					font-weight: var(--font-weight-bold);
					font-size: var(--font-size-h6);
				}
				p {
					margin-top: var(--spacing-s);
					line-height: var(--line-height-small);
				}
			}

			.review-process__count {
				color: var(--color-primary-light);
				margin-bottom: var(--spacing-s);
				position: relative;
				overflow: hidden;
				padding: 0 var(--grid-gap-sm);
				font-size: var(--font-size-h1);
				@include mq(medium) {
					font-size: var(--font-size-h2);
				}
				@include mq(large) { padding: 0 var(--spacing); }

				@include mq(medium) {
					&:before {
						content: '';
						position: absolute;
						top: 50%;
						left: 0;
						width: 35%;
						height: 1px;
						background-color: var(--color-primary);
					}
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						right: 0;
						width: 35%;
						height: 1px;
						background-color: var(--color-primary);
					}
				}
			}

			&:first-child .review-process__count:before,
			&:last-child .review-process__count:after { display: none; }

			@include mq(medium) {
				&:nth-child(3n + 3) .review-process__count:after,
				&:nth-child(3n + 4) .review-process__count:before { display: none; }
			}
			@include mq(large)  {
				&:nth-child(3n + 3) .review-process__count:after,
				&:nth-child(3n + 4) .review-process__count:before { display: block; }

				&:nth-child(5n) .review-process__count:after { display: none; }
				&:nth-child(6n) .review-process__count:before { display: none; }
			}
		}
	}

}
