figure {
	margin: 0;
}

figcaption {
	margin-top: 0;
}

figcaption p {
	font-size: var(--font-size-small);
}
