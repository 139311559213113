.review-panel {
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);
}

.review-panel__inner {
	@extend .container;

	@include mq(large) {
		display: grid;
		grid-gap: var(--grid-gap-s);
		grid-template-columns: 1fr 3fr;
	}
}

.review-panel__title {
	margin-bottom: var(--spacing-l);
	text-align: center;

	@include mq(large) {
		text-align: left;
	}
}

.review-panel__categories {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	@include mq(large) {
		align-items: flex-start;
		flex-direction: column;
	}
}

.review-panel__category {
	padding: 0.4rem;
}

.review-panel__cards {
	display: grid;
	grid-gap: var(--grid-gap-sm);
	grid-template-columns: 1fr;
	margin-top: var(--spacing-l);

	@include mq(small-major) {
		grid-template-columns: 1fr 1fr;
	}

	@include mq(large) {
		margin-top: 0;
	}

	@include mq(xlarge) {
		grid-template-columns: 1fr 1fr 1fr;
		margin-top: 0;
	}
}

.review-panel {
	.card {
		display: none;
	}

	.card.is-active {
		display: flex;
	}
}
