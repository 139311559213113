.icon-panel {
	text-align: center;
	padding-top: var(--section-spacing-l);
	padding-bottom: var(--section-spacing-l);
}

.icon-panel__inner {
	@extend .container;
}

.icon-panel__section-title {
	max-width: var(--content-width);
	margin: 0 auto;
}

.icon-panel__title {
	margin: 0;
}

.icon-panel__description {
	color: var(--color-text-light);
}

.icon-panel__col {
	margin-top: var(--spacing-xl);
	padding: 0 var(--spacing-xl);
}

.icon-panel__image {
	display: block;
	margin: 0 auto;
	width: 100%;
	max-width: 280px;
}
