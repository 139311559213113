.what-you-will-find-panel {
	padding-bottom: var(--section-spacing);
	text-align: center;

	.grid {
		grid-template-columns: 1fr;
		@include mq(medium) {
			grid-template-columns: 1fr 1fr 1fr;
		}
		@include mq(large) {
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
		}

		.grid__cell {
			text-align: center;
		}
	}
}

.what-you-will-find__header {
	margin-bottom: var(--spacing-xxl);
	text-transform: uppercase;
	font-size: var(--font-size-small);
	opacity: 0.6;
}

.what-you-will-find__image {
	margin: 0 auto;
}