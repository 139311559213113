.rating-row {
	margin: var(--spacing-xl) 0;

	@include mq(large) {
		display: flex;
		justify-content: space-between;
		margin: var(--spacing-xxl) 0;
	}
}

.rating-row__score {
	margin-bottom: var(--spacing);

	@include mq(large) {
		flex-basis: 25%;
		margin-bottom: 0;
	}
}

.rating-row__score .icon-tick {
	color: var(--color-green);
}

.rating-row__score .icon-cross {
	color: var(--color-green);
}

.rating-row__description {
	@include mq(large) {
		flex-basis: 70%;
	}
}

.rating-row__title {
	font-size: var(--font-size-small);
	text-transform: uppercase;
	color: var(--color-text-light);
	margin: var(--spacing-s) 0 var(--spacing-xs)!important;

	@include mq(medium) {
		font-size: var(--font-size-xsmall);
	}
}

.rating-row__title--alt {
	font-size: var(--font-size-lead);
	font-weight: var(--font-weight-bold);
	color: var(--color-text-base);
	text-transform: none;
	margin-top: 4px!important;
}

.rating-row__score {
	.checklist {
		font-size: var(--font-size-xsmall);

		li:before {
			top: 1px;
		}
	}
}

.rating-row__text {
	font-size: var(--font-size-xsmall);
	margin-top: 0;
}

.rating-row__alternative {
	margin-top: var(--spacing-s);
	font-size: var(--font-size-xsmall);
	line-height: var(--line-height-small);

	a {
		color: var(--color-primary);
	}
}

.rating-row__score .icon {
	width: 20px;
	height: 20px;

	&-cross {
		width: 15px;
		height: 15px;
	}
}

.rating-row__description p:first-child {
	margin-top: 0;
}

.rating-row__description p {
	font-size: var(--font-size-small);
}

// Overall rating
.rating-row--rating {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	background: var(--color-cream);
	padding: var(--spacing-l);
	border-radius: var(--border-radius);
	margin-top: var(--spacing-xxl);

	.rating-row__title {
		flex-basis: 70%;
		margin: 0 0 var(--spacing-xs)!important;
		font-weight: var(--font-weight-bold);
		color: var(--color-text-base);
	}

	.rating-row__rating {
		flex-basis: 30%;

		.rating-circle {
			margin-left: auto;
		}
	}

	.rating-row__description {
		flex-basis: 100%;
	}

	@include mq(large) {
		flex-wrap: nowrap;

		.rating-row__title {
			flex-basis: 20%;
		}

		.rating-row__rating {
			flex-basis: 18%;

			.rating-circle {
				margin-right: auto;
				margin-left: 0;
			}
		}

		.rating-row__description {
			flex: 0 1 62%;
		}
	}
}


// Rating summary
.rating-summary {
	display: flex;
	align-items: center;
	gap: var(--grid-gap-s);
	flex-wrap: wrap;
	margin: var(--spacing-l) 0;
}

.rating-summary__score {
	flex-basis: 60px;

	@include mq(medium) {
		flex-basis: 80px;
	}
}

.rating-summary__heading {
	flex-basis: 70%;

	@include mq(medium) {
		flex-basis: 30%;
	}
}

.rating-summary__bars {
	flex-basis: 100%;

	@include mq(medium) {
		flex: 1;
	}
}

h3.rating-summary__title {
	margin: 0;
	font-size: var(--font-size-h4);
	font-weight: var(--font-weight-bold);
}

p.rating-summary__subtitle {
	margin: 0;
	color: var(--color-text-light);
	font-size: var(--font-size-small);
	line-height: 1.3;
}


// Rating bars
.rating-bars {
	margin-left: auto;
	max-width: 400px;
}

.rating-bars__item {
	display: flex;
	align-items: center;
	margin: 6px 0;
}

.rating-bars__label,
.rating-bars__value {
	flex-basis: 30px;
	line-height: 1;
	font-size: var(--font-size-small);
}

.rating-bars__value {
	padding-left: 10px;
}

.rating-bars__meter {
	flex: 1;
	position: relative;
	background: var(--color-nav-grey);
	height: 4px;
	border-radius: 4px;
	overflow: hidden;

	> span {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background: var(--color-grey);
	}
}
