table,
table.tablepress {
	margin-top: var(--section-spacing);
	margin-bottom: var(--section-spacing);
	border-collapse: collapse;
    border-spacing: 0;
	border: none;
    width: 100%;
	vertical-align: middle;

	caption, tbody, tfoot, thead, tr, th, td {
		font-size: var(--font-size-small);
		line-height: 1.3;
		word-break: normal;
	}

	td[colspan] {
		-webkit-text-size-adjust: none;
		text-size-adjust: none;
	}

	thead th {
		background: none;
		font-weight: var(--font-weight-normal);
		text-transform: uppercase;
		color: var(--color-text-light);
		border-top: 1px solid var(--color-table-border);
		border-bottom: 1px solid var(--color-table-border);
		padding: 1rem 0.5rem;
		text-align: left;
	}

	thead th strong {
		color: var(--color-text-base);

		small {
			font-weight: var(--font-weight-normal);
		}
	}

	th, td {
		@include mq($until: medium-major) {
			min-width: 150px;
		}
	}

	td {
		border-bottom: 1px solid var(--color-table-border);
		padding: 1rem 0.5rem;

		&:first-child {
			font-weight: var(--font-weight-bold);
		}

		img{
			margin: 0 auto;
		}
	}

	a {
		color: var(--color-primary);
		text-decoration: underline;
	}

	p:empty{
		display: none;
	}
}

.table-alt-styling table.tablepress,
.table-border {
	thead th {
		border: 1px solid var(--color-table-border);
		text-align: left;
	}

	td {
		border: 1px solid var(--color-table-border);
	}
}

table.tablepress {
	thead th {
		text-align: center!important;
	}
}

.table-scroll-container {
	@include mq($until: medium-major) {
		overflow-x: scroll;
	}
}

.align-top {
	td { vertical-align: top !important; }
}

.ranking-table {
	thead th {
		text-align: center;
		@include mq($until: medium) {

			&:nth-child(1),
			&:nth-child(4) {
				display: none;
			}
	
			&:nth-child(2),
			&:nth-child(3) {
				width: 50%;
			}
		}
	}

	td:first-child {
		padding: 1rem .5rem;
	    vertical-align: middle;
	}

	td {
	    padding: 1rem 1.5rem;
	    vertical-align: top;
		
	}

	th:first-child {
		text-align: center;
	}

	tr th:first-child,
	tr td:first-child {
		min-width: 30px!important;
		text-align: center;
	}

	@include mq($until: medium) {

		display: block;
		box-sizing: border-box;
		
		tr {
			display: flex;
			flex-wrap: wrap;
		}

		th, td {
			flex: 1;
			width: 50%;
		}

		td .rating-circle--xsmall {
			margin: 10px auto;
		}

		td .checklist-cons,
		td .checklist-pros{

			display: inline-block;
    		width: 45%;
			vertical-align: top;
			word-wrap: break-word;
		}

		td .checklist-cons{
			padding-left: 3%;
		}

		td .checklist-cons p,
		td .checklist-pros p,
		td:nth-child(4) br{
			display: none;
		}

		tr:not(:first-child) td::before, tr:first-child td:nth-child(4)::before {
			background: var(--color-primary-light);
			border: 1px solid #f6fbff;
			content: attr(data-th);
			display: block;
			font-size: var(--font-size-small);
    		line-height: 1.3;
    		word-break: normal;
			height: auto;
			left: 0;
			margin-bottom: 15px;
			overflow: hidden;
			padding: 15px 6px 14px;
			position: relative;
			text-align: center;
			word-break: normal;
        }

		tr:first-child th:nth-child(2), tr:first-child th:nth-child(3),
		tr:nth-child(odd) td::before, tr:first-child td:nth-child(4)::before {
		
			background: var(--color-cream);
			
		}	


		/* Hide the first column on mobile */
		th:nth-child(1), td:nth-child(1),th:nth-child(1) {
			display: none;
		}

		/* Set the second and third columns to 50% width on mobile */
		th:nth-child(2), td:nth-child(2),
		th:nth-child(3), td:nth-child(3) {
			width: 50% !important;
			float: left;
		}

		th:nth-child(4), td:nth-child(4) {
			flex-basis: 100%;
			width: 100% !important;
		}
	}

	
}

// Fix styling of UL's inside tables
.content table {
	ul li:before {
		top: -5px!important;
	}

	.checklist-pros ul li:before,
	.checklist-cons ul li:before {
		top: 1px!important;
	}
}

table.tablepress td{

	word-break: break-word;

}