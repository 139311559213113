.block {
	margin-top: var(--section-spacing);
	margin-bottom: var(--section-spacing);
	scroll-margin-top: 90px;
	max-width: var(--content-width);
}

.block--spacing-small {
	margin-top: var(--spacing-s);
	margin-bottom: var(--spacing-s);
}

.block__title {
	display: flex;
	align-items: center;
	justify-content: space-between;

	h2 {
		margin: 0;
	}

	.btn {
		display: none;
		margin: 0;

		@include mq(medium-major) {
			display: inline-flex;
		}
	}
}
