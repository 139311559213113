#cookie-notice {
	z-index: z-scale(cookie)!important;

	.cookie-notice-container {
		width: 100%;
    	max-width: var(--container-width);
    	margin: 0 auto;
    	padding-right: 1em;
    	padding-left: 1em;
		@include mq(medium) {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.cn-button {
		@extend .btn;
		margin: 0;
	}

	.cn-text-container {
		@include mq(medium) {
			text-align: left;
			width: 75%;
		}
	}

	.privacy-link {
		text-transform: uppercase;
		text-decoration: underline;
	}

	.cn-close-icon {
		top: 20px;
		right: 5px;
		@include mq(medium) {
		    top: 50%;
		    right: 15px;
		}
	}
}
