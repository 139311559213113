// Modern CSS reset: https://github.com/hankchizljaw/modern-css-reset

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

html {
	scroll-behavior: smooth;
}

html, body {
	height: 100%;
}

/* Set core body defaults */
html,
body {
	font-family: var(--body-family);
	font-size: var(--font-size-base);
	line-height: var(--line-height);
	text-rendering: optimizeSpeed;
	color: var(--color-text-base);
	background-color: var(--body-background-color);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Make sure images with WordPress-added height and width attributes are scaled correctly */
img[class*="align"],
img[class*="wp-image-"] {
	height: auto;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
	animation-duration: 0.01ms !important;
	animation-iteration-count: 1 !important;
	transition-duration: 0.01ms !important;
	scroll-behavior: auto !important;
  }
}

.rich-snippet {
	display: none;
}
