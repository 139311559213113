p {
	margin-top: var(--spacing-l);
	margin-bottom: 0;
}

p.lead,
.lead p {
	font-size: var(--font-size-lead);
}

p.smallprint,
.smallprint p {
	font-size: var(--font-size-small);
	color: var(--color-text-light);
}

strong, b {
	font-weight: var(--font-weight-bold);
}

ul,
ol {
	list-style: none;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	padding-left: 1em;
}

ul ul,
ol ul,
ul ol,
ol ol {
	margin-top: 0;
	margin-bottom: 0;
}

li p {
	margin: 0;
}

blockquote {
	padding-left: 1em;
	border-left: 4px solid var(--color-text-base);

	&.primary--line { border-left-color: var(--color-primary); }
}

small {
	line-height: var(--line-height-small);
	display: inline-block;
}

a {
	color: var(--color-text-base);
	text-decoration: none;
}

a:hover {
	color: var(--color-text-hover);
	text-decoration: underline;
}

#disqus_thread{
	color: var(--color-text-hover);
	
	a{
		text-decoration: none;
		color: var(--color-primary);
	}

 	a:hover{
		text-decoration: underline;
	}
}

h1,
h2,
h3,
h4,
h5 {
	margin: 0;
	line-height: var(--line-height-headings);
	font-weight: var(--font-weight-normal);
	color: var(--heading-color);
}

h1, .heading-1 { font-size: var(--font-size-h1); }
h2, .heading-2 { font-size: var(--font-size-h2); }
h3, .heading-3 { font-size: var(--font-size-h3); }
h4, .heading-4 { font-size: var(--font-size-h4); }
h5, .heading-5 { font-size: var(--font-size-h5); }

h1.lead {
	font-size: var(--font-size-h1-lead);
}

code {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	font-style: normal;
	background-color: #f4f4f4;
	border-radius: 0.2rem;
	padding: 0.2rem;
	display: inline-block;
    max-width: var(--content-width-s);
    width: 100%;
    margin: 20px 0;
    font-size: var(--font-size-small);
    word-break: break-all;
    padding: 15px;
}

pre {
	background-color: #f4f4f4;
	border-radius: 0.2rem;
	padding: 0.5rem;
	overflow: auto;
	width: calc(96vw - 2em);
	line-height: 1.3;
}


// =============================================================================
// Subheader
// =============================================================================

.subheading {
	font-size: var(--font-size-small);
	font-weight: var(--font-weight-normal);
	text-transform: uppercase;
	color: var(--color-text-light);
	margin-top: var(--spacing-s);
}

// =============================================================================
// Underline
// =============================================================================

.has-underline {
	span {
		position: relative;
		z-index: 1;
	}

	span::after {
		content: '';
		position: absolute;
		bottom: -0.01rem;
		left: -0.25rem;
		right: -0.25rem;
		height: 3px;
		border-radius: 5px;
		background: var(--color-primary);
		transform: rotate(1deg);
		z-index: -1;
	}

	// blue – website builders
	&--primary span::after {
		background: var(--color-primary);
	}

	// green – ecommerce
	&--secondary span::after {
		background: var(--color-secondary);
	}

	// purple – hosting
	&--tertiary span::after {
		background: var(--color-tertiary);
	}

	// orange – email marketing
	&--quaternary span::after {
		background: var(--color-quaternary);
	}

	// yellow – crm
	&--quinary span::after {
		background: var(--color-quinary);
	}
}

.has-underline--reversed {
	span::after {
		transform: rotate(-1deg);
	  }
}

h1.has-underline {
	span::after {
		bottom: 5px;
		height: 4px;
	}
}

.has-ring {
	span {
		position: relative;
		z-index: 1;
	}
	span::after {
		content: '';
		position: absolute;
		top: 55.5%;
		left: 47.5%;
		transform: translate(-50%, -50%);
		height: 127%;
    	width: 120%;
		z-index: -1;
		background: url("data:image/svg+xml,%3Csvg width='205' height='79' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.105 65.266c24.76 5.625 50.469 7.939 75.708 10.282 16.751 1.556 33.332 2.727 50.028.222 20.074-3.013 40.656-5.66 53.028-23.267 4.88-6.946 6.404-19.041-.745-25.362-3.32-2.936-10.061-8.575-14.167-10.197-15.382-6.077-23.92-7.233-40.153-10.266C109.621.289 79.332-.116 39.92 9.74c-10.942 2.737-18.813 2.59-27.57 8.662-5.56 3.856-8.548 7.741-9.728 14.504C.58 44.62 11.281 52.413 19.884 57.782' stroke='%2357C0F9' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}

	&--primary span::after {
		background: url("data:image/svg+xml,%3Csvg width='205' height='79' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.105 65.266c24.76 5.625 50.469 7.939 75.708 10.282 16.751 1.556 33.332 2.727 50.028.222 20.074-3.013 40.656-5.66 53.028-23.267 4.88-6.946 6.404-19.041-.745-25.362-3.32-2.936-10.061-8.575-14.167-10.197-15.382-6.077-23.92-7.233-40.153-10.266C109.621.289 79.332-.116 39.92 9.74c-10.942 2.737-18.813 2.59-27.57 8.662-5.56 3.856-8.548 7.741-9.728 14.504C.58 44.62 11.281 52.413 19.884 57.782' stroke='%2357C0F9' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}

	&--secondary span::after {
		background: url("data:image/svg+xml,%3Csvg width='205' height='79' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.105 65.266c24.76 5.625 50.469 7.939 75.708 10.282 16.751 1.556 33.332 2.727 50.028.222 20.074-3.013 40.656-5.66 53.028-23.267 4.88-6.946 6.404-19.041-.745-25.362-3.32-2.936-10.061-8.575-14.167-10.197-15.382-6.077-23.92-7.233-40.153-10.266C109.621.289 79.332-.116 39.92 9.74c-10.942 2.737-18.813 2.59-27.57 8.662-5.56 3.856-8.548 7.741-9.728 14.504C.58 44.62 11.281 52.413 19.884 57.782' stroke='%2381E4AE' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}

	&--tertiary span::after {
		background: url("data:image/svg+xml,%3Csvg width='205' height='79' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.105 65.266c24.76 5.625 50.469 7.939 75.708 10.282 16.751 1.556 33.332 2.727 50.028.222 20.074-3.013 40.656-5.66 53.028-23.267 4.88-6.946 6.404-19.041-.745-25.362-3.32-2.936-10.061-8.575-14.167-10.197-15.382-6.077-23.92-7.233-40.153-10.266C109.621.289 79.332-.116 39.92 9.74c-10.942 2.737-18.813 2.59-27.57 8.662-5.56 3.856-8.548 7.741-9.728 14.504C.58 44.62 11.281 52.413 19.884 57.782' stroke='%238131BD' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;

	}

	&--quaternary span::after {
		background: url("data:image/svg+xml,%3Csvg width='205' height='79' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.105 65.266c24.76 5.625 50.469 7.939 75.708 10.282 16.751 1.556 33.332 2.727 50.028.222 20.074-3.013 40.656-5.66 53.028-23.267 4.88-6.946 6.404-19.041-.745-25.362-3.32-2.936-10.061-8.575-14.167-10.197-15.382-6.077-23.92-7.233-40.153-10.266C109.621.289 79.332-.116 39.92 9.74c-10.942 2.737-18.813 2.59-27.57 8.662-5.56 3.856-8.548 7.741-9.728 14.504C.58 44.62 11.281 52.413 19.884 57.782' stroke='%23EA663B' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}

	&--quinary span::after {
		background: url("data:image/svg+xml,%3Csvg width='205' height='79' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.105 65.266c24.76 5.625 50.469 7.939 75.708 10.282 16.751 1.556 33.332 2.727 50.028.222 20.074-3.013 40.656-5.66 53.028-23.267 4.88-6.946 6.404-19.041-.745-25.362-3.32-2.936-10.061-8.575-14.167-10.197-15.382-6.077-23.92-7.233-40.153-10.266C109.621.289 79.332-.116 39.92 9.74c-10.942 2.737-18.813 2.59-27.57 8.662-5.56 3.856-8.548 7.741-9.728 14.504C.58 44.62 11.281 52.413 19.884 57.782' stroke='%23F9D02D' stroke-width='3' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}
}

.has-ring--sm {
	span {
		position: relative;
		z-index: 1;
	}
	span::after {
		content: '';
		position: absolute;
		top: 57.5%;
		left: 52.5%;
		transform: translate(-50%, -50%);
		height: 135%;
		width: 135%;
		z-index: -1;
		background: url("data:image/svg+xml,%3Csvg width='78' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.63 6.595c-5.891-.832-11.7-2.14-17.598-2.974-8.63-1.22-17.496-2.437-24.908 4.072-3.645 3.2-7.818 10.328-5.005 15.26 1.892 3.32 5.266 5.52 8.307 7.369 7.375 4.484 15.592 7.167 23.937 8.736 12.474 2.345 27.492 2.414 37.578-7.853 2.103-2.14 4.353-5.128 4.384-8.422.054-5.672-5.78-9.957-9.665-12.426-5.34-3.395-11.38-5.33-17.434-6.665-6.93-1.53-14.147-2.556-21.271-2.03-3.205.237-6.432.735-9.51 1.842-1.803.65-2.61 1.345-3.846 2.778' stroke='%2357C0F9' stroke-width='1.815' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}
	&--primary span::after {
		background: url("data:image/svg+xml,%3Csvg width='78' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.63 6.595c-5.891-.832-11.7-2.14-17.598-2.974-8.63-1.22-17.496-2.437-24.908 4.072-3.645 3.2-7.818 10.328-5.005 15.26 1.892 3.32 5.266 5.52 8.307 7.369 7.375 4.484 15.592 7.167 23.937 8.736 12.474 2.345 27.492 2.414 37.578-7.853 2.103-2.14 4.353-5.128 4.384-8.422.054-5.672-5.78-9.957-9.665-12.426-5.34-3.395-11.38-5.33-17.434-6.665-6.93-1.53-14.147-2.556-21.271-2.03-3.205.237-6.432.735-9.51 1.842-1.803.65-2.61 1.345-3.846 2.778' stroke='%2357C0F9' stroke-width='1.815' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}

	&--secondary span::after {
		background: url("data:image/svg+xml,%3Csvg width='78' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.63 6.595c-5.891-.832-11.7-2.14-17.598-2.974-8.63-1.22-17.496-2.437-24.908 4.072-3.645 3.2-7.818 10.328-5.005 15.26 1.892 3.32 5.266 5.52 8.307 7.369 7.375 4.484 15.592 7.167 23.937 8.736 12.474 2.345 27.492 2.414 37.578-7.853 2.103-2.14 4.353-5.128 4.384-8.422.054-5.672-5.78-9.957-9.665-12.426-5.34-3.395-11.38-5.33-17.434-6.665-6.93-1.53-14.147-2.556-21.271-2.03-3.205.237-6.432.735-9.51 1.842-1.803.65-2.61 1.345-3.846 2.778' stroke='%2381E4AE' stroke-width='1.815' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}

	&--tertiary span::after {
		background: url("data:image/svg+xml,%3Csvg width='78' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.63 6.595c-5.891-.832-11.7-2.14-17.598-2.974-8.63-1.22-17.496-2.437-24.908 4.072-3.645 3.2-7.818 10.328-5.005 15.26 1.892 3.32 5.266 5.52 8.307 7.369 7.375 4.484 15.592 7.167 23.937 8.736 12.474 2.345 27.492 2.414 37.578-7.853 2.103-2.14 4.353-5.128 4.384-8.422.054-5.672-5.78-9.957-9.665-12.426-5.34-3.395-11.38-5.33-17.434-6.665-6.93-1.53-14.147-2.556-21.271-2.03-3.205.237-6.432.735-9.51 1.842-1.803.65-2.61 1.345-3.846 2.778' stroke='%238131BD' stroke-width='1.815' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;

	}

	&--quaternary span::after {
		background: url("data:image/svg+xml,%3Csvg width='78' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.63 6.595c-5.891-.832-11.7-2.14-17.598-2.974-8.63-1.22-17.496-2.437-24.908 4.072-3.645 3.2-7.818 10.328-5.005 15.26 1.892 3.32 5.266 5.52 8.307 7.369 7.375 4.484 15.592 7.167 23.937 8.736 12.474 2.345 27.492 2.414 37.578-7.853 2.103-2.14 4.353-5.128 4.384-8.422.054-5.672-5.78-9.957-9.665-12.426-5.34-3.395-11.38-5.33-17.434-6.665-6.93-1.53-14.147-2.556-21.271-2.03-3.205.237-6.432.735-9.51 1.842-1.803.65-2.61 1.345-3.846 2.778' stroke='%23EA663B' stroke-width='1.815' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}

	&--quinary span::after {
		background: url("data:image/svg+xml,%3Csvg width='78' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M49.63 6.595c-5.891-.832-11.7-2.14-17.598-2.974-8.63-1.22-17.496-2.437-24.908 4.072-3.645 3.2-7.818 10.328-5.005 15.26 1.892 3.32 5.266 5.52 8.307 7.369 7.375 4.484 15.592 7.167 23.937 8.736 12.474 2.345 27.492 2.414 37.578-7.853 2.103-2.14 4.353-5.128 4.384-8.422.054-5.672-5.78-9.957-9.665-12.426-5.34-3.395-11.38-5.33-17.434-6.665-6.93-1.53-14.147-2.556-21.271-2.03-3.205.237-6.432.735-9.51 1.842-1.803.65-2.61 1.345-3.846 2.778' stroke='%23F9D02D' stroke-width='1.815' stroke-linecap='round'/%3E%3C/svg%3E") no-repeat center center;
		background-size: contain;
	}
}

// =============================================================================
// Checklist
// =============================================================================
.checklist,
.checklist-pros ul,
.checklist-cons ul {
	list-style: none;
	padding-left: 0;

	li {
		position: relative;
		padding-left: 30px;
		margin-bottom: 1em;
	}

	li:before {
		position: absolute;
		top: 3px;
		left: 0;
		content: ' ';
		width: 20px;
		height: 20px;
		background-image: url(../images/tick.svg);
		background-repeat: no-repeat;
		background-position: center;
	}

	li h4 {
		margin: 0 0 0.25em 0;
		font-size: var(--font-size-small);
		font-weight: var(--font-weight-bold);
	}

	li.cross:before {
		background-image: url(../images/cross.svg);
	}

	&--cons {
		li:before {
			background-image: url(../images/cross.svg);
		}
	}

	&--dividers {
		li {
			border-bottom: 1px solid var(--color-nav-grey);
			padding-top: 1rem;
			padding-bottom: 1rem;
		}

		li:before {
			top: 18px;
		}
	}

	&--tight {
		li {
			margin-bottom: 0.1em;
		}
	}
}

.checklist-pros ul,
.checklist-cons ul {
	margin-top: 0;
	margin-bottom: 0;

	li {
		margin-bottom: 0.1em;
	}

	li:before {
		top: 0;
	}
}

.checklist-cons ul {
	li:before {
		background-image: url(../images/cross.svg);
	}
}
