.feature-image {
	padding-top: var(--spacing-l);
	padding-bottom: var(--spacing-l);
	@include mq(large) {
		padding-top: var(--spacing-xxl);
		padding-bottom: var(--spacing-xxl);
	}

	.feature-image__media {
		img {
			width: 100%;
			border-radius: var(--border-radius-sm);
			@include mq(large) {
				border-radius: var(--border-radius);
			}
		}
	}
}