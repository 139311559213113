.author-bio {
	.container {
		@include mq(medium) {
			display: flex;
		}

		.author-bio__image {
			margin: var(--spacing-xl) 0 var(--spacing-xl) 0;
			@include mq(medium) {
				flex-basis: 250px;
				margin: 0 var(--spacing-xl) 0 0;
			}
			@include mq(large)  { margin: 0 var(--spacing-xxl) 0 0; }

			img {
				width: 100%;
				@include mq(medium) { width: auto; }
			}
		}

		.author-bio__content {
			@include mq(medium) {
				width: 50%;
			}

			.author-bio__meta {
				@include mq(large) {
					display: flex;

					.author-bio__role:after {
						content: '•';
						margin: 0 8px;
					}
				}

				p {
					margin: 0;
					font-size: var(--font-size-small);
				}
			}

			.author-bio__name {
				margin-bottom: var(--spacing);
			}

			blockquote {
				h1, h2, h3, h4 { line-height: var(--line-height-small); }
			}

			p {
				color: var(--color-text-light);
			}
		}
	}
}

.authors-articles__title {
	text-transform: uppercase;
	font-size: var(--font-size-small);
	color: var(--color-text-light);
	text-align: center;
	margin-bottom: var(--spacing-xl);
	@include mq(medium) {
		margin-bottom: var(--spacing-l);
		text-align: left;
	}
}

.author-bio__role {
	margin-bottom: var(--spacing-l);
	text-transform: uppercase;
	font-size: var(--font-size-small);
	color: var(--color-text-light);
}
