.review-section-header {
	margin-bottom: var(--spacing);
}

.review-section-header__title {
	font-weight: var(--font-weight-bold);

	span {
		display: block;
		font-weight: var(--font-weight-normal);
		font-size: var(--font-size-h2);
	}
}

.review-section-header__meta {
	text-align: left;
	color: var(--color-text-light);
	margin-top: var(--spacing-s);
	font-size: var(--font-size-xsmall);
}

a.review-section-header__link {
	text-transform: uppercase;
	color: var(--color-primary);
	margin-left: 5px;
	font-weight: var(--font-weight-bold);
	text-decoration: underline;
}