.alert {
	display: none;
    background: var(--color-grey);
}

.alert__inner {
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 var(--spacing);
}

.alert__flags {
	display: flex;
	background: black;
	margin: 0 1rem;
	padding: 2px;
	border-radius: 8px;

	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0;
		padding: 0;
	}

	li {
		padding: 0 0.5rem;
	}

	img {
		width: 15px;
		height: 10px;
	}
}

.alert p,
.alert a {
	line-height: 1.2;
    color: white;
    margin: 0;
	font-size: var(--font-size-small);
}

.alert a {
	font-weight: var(--font-weight-bold);
	text-decoration: underline;
}

.alert__close {
	border: 0;
	cursor: pointer;
	background: transparent;
	margin-left: 5px;

	svg {
		height: 14px;
		width: 14px;
	}
}

.form-alert {
	border: 1px solid var(--color-grey);
	border-radius: 50px;
	padding: var(--spacing-s);
	display: none;
	transition: all 0.25s ease-out;

	p { margin: 0; }

	&.form-alert__error {
		border-color: var(--color-red);
		color: var(--color-red);
	}

	&.form-alert__success {
		border-color: var(--color-green);
		color: var(--color-green);
	}
}