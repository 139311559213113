.page-header--methodology {
	.page-header__inner {
		text-align: center;
		margin: 0 auto;
		@include mq(large) {
			max-width: 75%;
		}
	}

	.page-header__subhead {
		font-size: var(--font-size-small);
		text-transform: uppercase;
		margin-bottom: var(--spacing);
		color: var(--color-text-light);
	}

	.page-header__desc {
		color: var(--color-text-base);
		margin-top: var(--spacing-xl);
		color: var(--color-text-light);
		line-height: 1.4;
	}

	h2 {
		font-size: var(--font-size-h1);
	}
}
