// =============================================================================
// Site
// =============================================================================

.global-container {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.container {
	margin: 0 auto;
	padding: 0 1em;
	width: 100%;
	max-width: var(--container-width);

	@include mq(xlarge) {
		&.container-width-l { width: var(--container-width-l); }
		&.container-width-m { width: var(--container-width-m); }
		&.container-width-s { width: var(--container-width-s); }
		&.container-width-xs { width: var(--container-width-xs); }
		&.container-width-xxs { width: var(--content-width-s); }
	}
}

.main {
	flex: 1;
}

.content-wrap {
	@include mq(large) {
		display: grid;
		grid-gap: var(--grid-gap-sm);
		grid-template-columns: repeat(12, 1fr);

		.sidebar {
			grid-column: 1 / 3;
		}

		.content {
			grid-column: 4 / 12;
		}
	}
}

.grid {
	display: grid;
	grid-gap: var(--grid-gap);

	&.grid--gap-0  { grid-gap: 0; }
	&.grid--gap-s  { grid-gap: var(--grid-gap-s); }
	&.grid--gap-sm { grid-gap: var(--grid-gap-sm); }

	&.grid--gap-l  {
		grid-gap: var(--grid-gap-sm);
		@include mq(medium) { grid-gap: var(--grid-gap-l); }
	}

	@include mq(medium) {
		&.grid--two-col   { grid-template-columns: 1fr 1fr; }
		&.grid--three-col { grid-template-columns: 1fr 1fr 1fr; }
		&.grid--four-col  { grid-template-columns: 1fr 1fr 1fr 1fr; }
		&.grid--two-thirds-one-third { grid-template-columns: 2fr 1fr; }
		&.grid--one-third-two-thirds { grid-template-columns: 1fr 2fr; }
	}

	@include mq(large) {
		&.grid--lg-two-col   { grid-template-columns: 1fr 1fr; }
		&.grid--lg-three-col { grid-template-columns: 1fr 1fr 1fr; }
		&.grid--lg-four-col  { grid-template-columns: 1fr 1fr 1fr 1fr; }
	}
}
