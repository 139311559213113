.downloads-panel {
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);
	.ebook-form {

		.entry_mcq label, .listsize, .entry__error{
			margin: 0 0 10px !important;
			font-size: var(--font-size-small);
			font-weight: 400!important;
		}
	
		#LISTSIZE{
			height: 32px;
			font-family: var(--body-family);
		}
	
	}
}

.downloads-panel__inner {
	@extend .container;
}

.downloads-panel__section-title {
	max-width: var(--content-width);
	margin: 0 auto;
	text-align: center;
}

.downloads-panel__group {
	margin-top: var(--spacing-xxl);
}

.downloads-panel__group.grid--two-col .downloads-panel__col {
	h3, p { text-align: center; }

	button {
		margin-bottom: 0;
	}

	label {
		font-size: var(--font-size-small);
	}

	&:first-child {
		border-right: 1px solid var(--color-lightest-grey);
	}
}



.downloads-panel__col-inner {
	margin: 0 auto;

	@include mq(large) {
		max-width: 440px;
	}
	@include mq(small,large){
		padding: 0 20px 0;
	}
	
}

.downloads-panel__image {
	margin: 0 auto var(--spacing-xl);
	max-width: 300px;
}