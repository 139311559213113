.author-detail-block {
	padding-top: var(--section-spacing);
	border-top: 1px solid var(--color-text-base);
}

.author-row {
	margin: var(--spacing-xl) 0;

	@include mq(large) {
		display: flex;
		justify-content: space-between;
	}
}

.author-row__profile {
	@include mq(large) {
		flex-basis: 15%;
	}

	.user-profile {
		margin: 0 auto var(--spacing);
		max-width: 125px;
	}
}

.author-row__bio {
	@include mq(large) {
		flex-basis: 80%;
	}
}

h3.author-row__name {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0;
	font-size: var(--font-size-h5);
	font-weight: var(--font-weight-bold);

	a {
		color: var(--color-text-base)!important;
	}

	@include mq(large) {
		justify-content: flex-start;
	}
}

p.author-row__title {
	margin-top: 0;
	color: var(--color-text-light);
	font-size: var(--font-size);
	text-align: center;

	@include mq(large) {
		text-align: left;
	}
}

.author-detail-block__btn {
	display: block;
	width: 100%;
	max-width: 220px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
}
