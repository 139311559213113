.examples {
	background: var(--color-cream);
	padding: var(--spacing) var(--spacing) var(--spacing-xl);
	position: relative;

	@include mq(medium) {
		padding: var(--spacing-xl) var(--spacing-xl) var(--spacing-xxl);
	}
}

.examples__title {
	font-size: var(--font-size-h3);
	margin: 0!important;
}

.examples__category-list {
	display: none;

	@include mq(medium) {
		display: block;
		border-bottom: 1px solid rgba(0,0,0,0.2);
		margin-bottom: var(--spacing-l);
		padding-bottom: var(--spacing-l);
		padding-top: var(--spacing);
	}
}

.examples__category-dropdown {
	display: block;

	@include mq(medium) {
		display: none;
	}
}

.examples__category-dropdown select {
	width: 100%;
	margin: var(--spacing) 0;
	background: transparent;
	border: 1px solid var(--color-light-grey);
	border-radius: var(--border-radius);
	color: var(--color-text-light);
	padding: 10px;
}

/* Slider */
.examples-slider,
.examples-slider .swiper-slide {
	width: 100%;
}

.examples-slider__title {
	font-size: var(--font-size-h4);
	font-weight: var(--font-weight-bold);
}

.examples-slider__description {
	font-size: var(--font-size-small);
	margin-top: var(--spacing-s);
}

.examples-slider-site-info {
	display: flex;
	flex-direction: column;
	margin-top: var(--spacing);

	@include mq(large) {
		flex-direction: row;
	}
}

.examples-slider-site-info__item {
	flex: 1;

	@include mq(large) {
		border-right: 1px solid rgba(0,0,0,0.2);
		padding: 0 var(--spacing);

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			border-right: 0;
		}
	}
}

h4.examples-slider-site-info__title {
	margin-top: var(--spacing-s);
	font-size: var(--font-size-xsmall);
	color: rgba(0,0,0,0.6);
	text-transform: uppercase;

	@include mq(large) {
		margin: 0;
	}
}

.examples-slider-site-info__text {
	margin: 0;
	font-size: var(--font-size-small);
}

.examples-slider-prev,
.examples-slider-next {
	display: none;
	height: 20px;
	width: 20px;
	position: absolute;
	top: 45%;
	cursor: pointer;

	&.swiper-button-disabled {
		opacity: 0;
		visibility: hidden;
	}

	@include mq(large) {
		display: block;
	}
}

.examples-slider-prev {
	left: 8px;
	background: url('../images/arrow-left.svg') center no-repeat;
	background-size: 100%;
}

.examples-slider-next {
	right: 8px;
	background: url('../images/arrow-right.svg') center no-repeat;
	background-size: 100%;
}

.examples .swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: var(--color-grey);
}
