.img-optimisation__form {
	display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
   	@include mq(medium) {
		margin-top: var(--spacing-l);
	}

	input[type='submit'] {
		margin-left: var(--spacing);
	}
}

.optim-error   { color: #6B0505; }
.optim-success { color: var(--color-primary); }