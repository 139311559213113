.domain-connector {
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);
	overflow: hidden;
	text-align: center;

	.domain-connector__title {
		span {
			width: 32px;
			height: 32px;
			line-height: 32px;
			border-radius: 100%;
			background: #FAF7F1;
			color: var(--color-grey);
			display: inline-block;
			margin-bottom: var(--spacing);
			font-size: var(--font-size-small);
		}
	}

	.domain-connector__panel + .domain-connector__panel {
		margin-top: var(--section-spacing);
	}

	.domain-connector__options {
		list-style: none;
		margin: var(--spacing-xl) 0 0 0;
		padding: 0;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: var(--grid-gap-s);
		@include mq(medium) {
			grid-gap: var(--grid-gap-sm);
		}

		.domain-connector__option {
			.domain-connector__option--selector {
				width: 100%;
			    position: relative;
			    display: block;
			    border-radius: 6px;
			    border: 1px solid #e5e5e5;
			    box-sizing: border-box;
			    padding-top: 66.66666%;
			    transition: all 0.25s ease-out;
			    background-repeat: no-repeat;
			    background-position: center;
			    background-size: 75%;
			    @include mq(medium) {
			    	background-size: 66.6666%;
			    }

			    img {
			    	position: absolute;
			    	left: 50%;
			    	top: 50%;
			    	transform: translate(-50%, -50%);
			    }

				.domain-connector__option--status {
					position: absolute;
					background: #FFFFFF;
					border: 1px solid rgba(0, 0, 0, 0.2);
					box-sizing: border-box;
					border-radius: 2px;
					left: 5px;
					top: 5px;
					width: 12px;
					height: 12px;
					@include mq(medium) {
						left: 10px;
						top: 10px;
						width: 20px;
						height: 20px;
					}
				}
			}

			input[type='radio']:checked+label {
				background-color: var(--color-cream);
				border-color: var(--color-cream);

				.domain-connector__option--status {
					border-color: var(--color-primary);
					background: #fff url("data:image/svg+xml,%3Csvg width='11' height='7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.277 3.897 6 10 1' stroke='%2357C0F9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center center;
				}
			}

			input[type='radio'] { display: none; }
		}
	}


}