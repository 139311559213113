.page-header--blog {
	.page-header__inner {
		@include mq(medium) {
			height: 300px;
			flex-flow: row wrap;
		}

		h1 { width: 100%; }
	}
	.page-header__categories {
		font-size: var(--font-size-small);
		text-transform: uppercase;
		display: none;
		@include mq(medium) {
			display: flex;
			justify-content: space-around;
			flex-flow: row wrap;
		}
		li {
			margin: 0 var(--spacing);

			.page-header__categories--link {
				position: relative;
				padding-bottom: 3px;
				&:after {
					content: '';
					width: 0;
					height: 1px;
					background-color: #1A1C22;
					position: absolute;
					bottom: 0;
					left: 0;
					visibility: hidden;
					opacity: 0;
					transition: all 0.3s ease;
				}
				&:hover, &:focus {
					color: var(--color-text-base);
					text-decoration: none;
					&:after {
						opacity: 1;
						visibility: visible;
						width: 100%;
					}
				}
				&.active {
					font-weight: var(--font-weight-bold);
					&:after {
						opacity: 1;
						visibility: visible;
						width: 100%;
					}
				}
			}
		}
	}
}

.category-selector__select {
	border: 1px solid var(--color-grey);
	width: 100%;
	height: 50px;
	border-radius: var(--border-radius);
	background: transparent;
	background: url(../images/chevron-down.svg) no-repeat 95% center;
	color: var(--color-text-base);
	padding: 0 15px;
	-webkit-appearance: none;
	appearance: none;
	opacity: .7
}
