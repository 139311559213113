// =============================================================================
// Helper classes
// =============================================================================

.u-bold { font-weight: bold!important; }
.u-semibold { font-weight: 500!important; }
.u-regular { font-weight: normal!important; }
.u-light { font-weight: 300!important; }
.u-italic { font-style: italic!important; }
.u-caps { text-transform: uppercase!important; }
.u-left-align { text-align: left!important; }
.u-center { text-align: center!important; }
.u-right-align { text-align: right!important; }

// Margins
.u-mt-jumbo { margin-top: var(--spacing-jumbo)!important; }
.u-mt-xxxl { margin-top: var(--spacing-xxxl)!important; }
.u-mt-xxl { margin-top: var(--spacing-xxl)!important; }
.u-mt-xl { margin-top: var(--spacing-xl)!important; }
.u-mt-l { margin-top: var(--spacing-l)!important; }
.u-mt { margin-top: var(--spacing)!important; }
.u-mt-s { margin-top: var(--spacing-s)!important; }

.u-mb-xxl { margin-bottom: var(--spacing-xxl)!important; }
.u-mb-xl { margin-bottom: var(--spacing-xl)!important; }
.u-mb-l { margin-bottom: var(--spacing-l)!important; }
.u-mb { margin-bottom: var(--spacing)!important; }
.u-mb-s { margin-bottom: var(--spacing-s)!important; }

.u-m0 { margin: 0!important; }
.u-mb0 { margin-bottom: 0!important; }
.u-mt0 { margin-top: 0!important; }

// Padding
.u-p0 { padding: 0!important; }
.u-p { padding: var(--spacing)!important; }
.u-pb-xxl { padding-bottom: var(--spacing-xxl)!important; }

// Sections
.section-spacing { padding-top: var(--section-spacing); padding-bottom: var(--section-spacing); }

.section-spacing-l {
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);
	@include mq(medium) {
		padding-top: var(--section-spacing-l);
		padding-bottom: var(--section-spacing-l);
	}
}

.section-spacing-less {
	padding-top: var(--section-spacing);
	padding-bottom: var(--section-spacing);

	h2:first-child{
		margin-top: 0;
	}
	
	img{
		border-radius: var(--border-radius);
	}

	h2:not(h2:first-child){
		margin-top: var(--heading-margin);
	}
}

// Responsive
.u-mobile-only {
	@include mq(medium-major) {
		display: none;
	}
}

.u-desktop-only {
	display: none;

	@include mq(medium-major) {
		display: block;
	}
}

// Colours
.u-bg-white		{ background: var(--color-white)!important; }
.u-bg-cream		{ background: var(--color-cream)!important; }
.u-bg-grey		{ background: var(--color-grey)!important; }

// Text colours
.u-color-blue { color: var(--color-primary); }
.u-color-green { color: var(--color-secondary); }
.u-color-purple { color: var(--color-tertiary); }
.u-color-orange { color: var(--color-quaternary); }
.u-color-yellow { color: var(--color-quinary); }
.u-color-lilac { color: var(--color-senary); }
.u-color-pink { color: var(--color-septenary); }
.u-color-turquoise { color: var(--color-octonary); }
.u-color-red { color: var(--color-nonary); }
.u-color-lime { color: var(--color-denary); }

// Hover colours
.u-hover-blue:hover { color: var(--color-primary)!important; }
.u-hover-green:hover { color: var(--color-secondary)!important; }
.u-hover-purple:hover { color: var(--color-tertiary)!important; }
.u-hover-orange:hover { color: var(--color-quaternary)!important; }
.u-hover-yellow:hover { color: var(--color-quinary)!important; }
.u-hover-lilac:hover { color: var(--color-senary)!important; }
.u-hover-pink:hover { color: var(--color-septenary)!important; }
.u-hover-turquoise:hover { color: var(--color-octonary)!important; }
.u-hover-red:hover { color: var(--color-nonary)!important; }
.u-hover-lime:hover { color: var(--color-denary)!important; }

// WordPress specifc
img.alignright      { float: right; margin: 0 0 1em 1em; }
img.alignleft       { float: left; margin: 0 1em 1em 0; }
img.aligncenter     { display: block; margin-left: auto; margin-right: auto; }
.alignright         { float: right; }
.alignleft          { float: left; }
.aligncenter        { display: block; margin-left: auto; margin-right: auto; }
.textcenter        { text-align: center; }

// Screen reader
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

// debugging
@if $debug == true {
    * {
        box-shadow: inset 0 0 0 1px red;
    }
}

.code-wrap {
	overflow: auto;
	width: 100%;
	max-height: 200px;
}
