.global-header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	will-change: transform;
	transition: transform 200ms linear, background 200ms linear;
	z-index: z-scale(header);

	@include mq($until: large) {
		&--pinned {
			transform: translateY(0%);
		}

		&--unpinned {
			transform: translateY(-100%);
		}

		&--not-top {
			background: white;
		}
	}

	@include mq(large) {
		position: absolute;
		top: 10px;
	}
}

.global-header.global-header--alert {
	top: 68px;

	&.global-header--unpinned,
	&.global-header--pinned {
		top: 0;
	}

	&.global-header--top {
		top: 68px;
	}

	.nav-open & {
		top: 0;
	}
}

// Fix for if user is logged in
body.admin-bar .global-header {
	top: 32px;

	&.global-header--unpinned,
	&.global-header--not-top{
		top:0;
	}
	
	&.global-header--top {
		top: 40px;
	}

}

body.admin-bar .global-header--alert {
	top: 100px;

	&.global-header--unpinned,
	&.global-header--pinned {
		top: 100px;
	}

	&.global-header--top {
		top: 100px;
	}
}

.global-header__inner {
	@extend .container;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: var(--spacing-s);
	transition: background 0.2s ease-in-out;
}

.global-header__nav,
.global-header__search {
	display: none;

	@include mq(large) {
		display: block;
	}
}

.global-header__search {
	margin-right: var(--spacing);

	@include mq(xlarge) {
		margin-right: var(--spacing-l);
	}
}

.global-header__seach-btn {
	border: 0;
	cursor: pointer;
	background: transparent;
}

.global-header__logo {
	display: block;
	color: var(--color-text-base);
	margin-left: 10px;
	z-index: z-scale(nav-overlay);

	&:hover {
		color: var(--color-text-base);
	}

	.icon-logo {
		width: 122px;
		height: auto;
		max-height: 30px;
	}

	.custom-top-logo{
		max-height: 20px;
		max-width: 122px;
		display: inline-block;
		vertical-align: middle;
	}

	@include mq(xlarge) {
		.icon-logo {
			width: 170px;
			height: auto;
			margin-top: -5px;
			max-height: 40px;
		}
		.custom-top-logo{
			max-height: 45px;
		}
	}
}

.global-header__menu-btn {
	display: inline-block;
	background: black;
	color: white;
	border: 0;
	z-index: z-scale(nav-overlay);

	@include mq(large) {
		display: none;
	}
}


#topHeader{

	display: block;
	z-index: 2;

	.alert__inner{
		height: auto;
		min-height: 60px;
	}

	.alert__content a{
		color: var(--color-text-link);
    	text-decoration: none;
	}

	.alert__content a:hover{
		text-decoration: underline;
	}

	.btn {
		border-radius: 50px;
		display: flex;
		margin: 0 0.5rem;
    	min-width: 125px;
		padding: 2px;
		border: 1px solid var(--body-background-color);
		padding: 0.5em 1em;
		text-decoration: none;
		text-align: center;
		font-size: var(--font-size-xsmall);
	}

	.btn:hover{
		background-color: var(--color-text-reversed)!important;
		border: 1px solid var(--color-text-reversed);
    	color: var(--color-text-base)!important;
	}

	@include mq(large) {
		.btn {
			margin: 0 2rem;
    		padding: 0.5em 2em;
    		min-width: 250px;
		}
	}
}

// Fix for if user is logged in on mobile
@include mq($until: large) {

	body.admin-bar .global-header {

		&.global-header--top:has(.is-active){
			top:40px;
		}
	}
	
}