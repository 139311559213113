.comments {
	background: var(--color-cream);
	width: 100%;
	padding: var(--spacing-xl) 0;
	margin-top: var(--spacing-l);
}

.comments__inner {
	@extend .container;
	max-width: var(--content-width);
}
