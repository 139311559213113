.breadcrumbs {
	margin: 0 0 var(--spacing);
	color: var(--color-primary);
	line-height: var(--line-height-small);
	font-size: var(--font-size-xsmall);
	text-align: left;

	a {
		color: var(--color-text-base);
	}
}
