#user-review-form {
	label {
		font-weight: var(--font-weight-bold);
		margin-bottom: 5px;
		margin-top: var(--spacing-l);
	}

	select {
		background: url("data:image/svg+xml,%3Csvg width='13' height='8' viewBox='0 0 13 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M1 1L6.5 7L12 1' stroke='%231A1C22'/%3E%3C/svg%3E%0A") no-repeat 98% center;
		background-size: 13px;
	}

	.ur-row {
		display: flex;
		align-items: center;
		margin: var(--spacing-s) 0;

		label {
			margin: 0;
			width: 22%;

			@include mq(medium) {
				width: 16%;
			}
		}
	}

	.ur-row.hidden {
		display: none;
	}

	.ur-add-like a,
	.ur-add-dislike a {
		display: inline-block;
		color: var(--color-primary);
		margin: 5px 0 var(--spacing) 0;
		cursor: pointer;

		&:hover {
			text-decoration: none;
		}
	}

	textarea {
		min-height: 200px;
	}

	#count_message {
		margin-top: 2px;
		text-align: right;
	}

	.hide-me {
		display: none;
	}
}
