.user-review-panel {
	margin-bottom: var(--spacing-xl);
	padding: var(--spacing-l);
	border-radius: var(--border-radius);
	box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);

	@include mq(large) {
		padding: var(--spacing-xxl);
	}
}

.user-review-panel__date {
	text-align: right;
	margin: 0;
}

.user-review-panel__user {
    font-weight: var(--font-weight-bold);
}

.user-review-panel__link {
	margin: var(--spacing) 0 0;
    font-weight: var(--font-weight-normal);
		a{
			color: var(--color-primary)
		};
}

.user-review-panel__body {
	@include mq(large) {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
}

.user-review-panel__content {
	@include mq(large) {
		flex: 0 0 60%;
	}
}


.user-review-panel__aside {
	@include mq(large) {
		flex: 0 0 25%;
	}
}

.user-review-panel__footer {
	@include mq(large) {
		flex: 0 0 100%;
	}
}

.user-review-panel__content-inner {
	border-left: 4px solid var(--color-primary);
	padding-left: 2rem;
	margin-bottom: var(--spacing-l);
}

.user-review-panel__aside-title {
	text-transform: uppercase;
	margin-top: var(--spacing-l);
	margin-bottom: var(--spacing-s);
}

.user-review-panel__btn {
	margin-right: 1rem;
	margin-bottom: 0;
}
