body.search-open {
	overflow: hidden;
}

.global-search {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, .75);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	transition: all 0.35s ease-in-out;
	padding: 0 15px;
	@include mq(medium) { padding: 0; }
}

.global-search.is-active {
	opacity: 1;
	visibility: visible;
	z-index: z-scale(modal);
}

.global-search__window {
	width: 100%;
	max-width: var(--container-width-s);
	margin: 0 auto;
	overflow-y: auto;
    background: #fff;
    height: 85%;
    @include mq(medium) {
    	height: 75%;
    }
}

.global-search__title {
	position: sticky;
	z-index: 5;
	width: 100%;
	top: 0;
	left: 0;
	background: var(--color-cream);
	padding: 15px 20px 10px 20px;
	p { margin: 0; }
}

.global-search__inner {
	padding: 20px;
}

.global-search__btn {
	background: 0;
	padding: 0;
	border: 0;
	text-decoration: underline;
	font-size: var(--font-size-h4);
	font-family: var(--body-family);
	line-height: var(--line-height-headings);
    font-weight: var(--font-weight-normal);
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    width: 25px;
    height: 25px;
	@include mq(medium) {
		width: 30px;
    	height: 30px;
	}

    &:before, &:after {
    	content: '';
    	width: 100%;
    	height: 1px;
    	position: absolute;
    	left: 50%;
    	top: 50%;
    	transform: translate(-50%, -50%) rotate(45deg);
    	background: var(--color-text-base);
    }

    &:after { transform: translate(-50%, -50%) rotate(-45deg); }

    &:hover { opacity: .65; }
}

.global-search {
	.search-form {
		position: relative;
		display: block;
		width: 100%;
	}

	.search-form__input {
		width: 100%;
		font-size: var(--font-size-h2);
		padding: 8px 0;
		background: transparent;
		border: 0;
		border-bottom: 1px solid black;
	}

	.search-form__submit {
		@extend .btn;
		@extend .btn--small;
		position: absolute;
		right: 0;
		top: 0;

		svg {
			display: none;
		}
	}
}

.bcs-searchbox {
	font-family: var(--body-family) !important;
    color: var(--heading-color) !important;
    height: 45px !important;
    max-height: 45px !important;
}

.bcs-searchbox-submit {
	height: 49px !important;
	cursor: pointer !important;
	vertical-align: bottom;
	background: url("data:image/svg+xml,%3Csvg width='27' height='28' viewBox='0 0 27 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.2135 21.4271C16.8543 21.4271 21.4271 16.8543 21.4271 11.2135C21.4271 5.57276 16.8543 1 11.2135 1C5.57276 1 1 5.57276 1 11.2135C1 16.8543 5.57276 21.4271 11.2135 21.4271Z' stroke='%23222222' stroke-width='2'/%3E%3Cline x1='18.043' y1='19.1857' x2='25.7032' y2='26.8459' stroke='%23222222' stroke-width='2'/%3E%3C/svg%3E%0A") no-repeat center center !important;
}

.bcs-query-rewrite, .bcs-result {
	padding: 12px 0 !important;
}